// react
import {FC, useMemo, useEffect, useState, Dispatch, SetStateAction, useContext, createRef} from 'react';
// redux
import {Store} from 'redux/root';
import {useSelector} from 'react-redux';
import {getAllHashtags} from 'redux/hashtag-service/actions';
// other packages
import {Moment} from 'moment';
import {useTranslation} from 'react-i18next';
//formik
import {Field, Form, FormikErrors, useFormikContext} from 'formik';
//types
import {SelectOption} from 'shared/inputs/Select/types';
import {ManualOpportunityInitialValuesType} from 'core/types/index';
import {IGetLocationIdByAddressRequest, VOLUNTEER_ACTIVE_STATUS} from '@joc/api-gateway';
//components
import Input from 'shared/inputs/Input';
import Select from 'shared/inputs/Select';
import Loader from 'shared/components/Loader';
import {TimeLimit} from 'components/TimeLimit';
// import TextEditor from 'shared/components/TextEditor';
import ItemGallery from 'components/Volunteer/UploadSeveralPhotos';
import {selectorGetHashtags} from 'redux/hashtag-service/selectors';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import {DatePicker} from 'shared/components/Pickers/NewUI/DatePicker';
import {getVolunteerOrganisations} from 'components/Organization/InputSearchOrg/helpers';
// selectors
import {selectorGetUserData, selectorGetUserOrganizations} from 'redux/user-service/selector';
// helpers
import {getTransformOrg} from './helper';
// hooks
import {useLoading} from 'hooks/useLoading';
import NoOrganizationIcon from '../../../../assets/image/no-organization.png';
//styles
import styles from './ManualOpportunityMainForm.module.scss';
import {HashtagField} from 'shared/components/HashtagField';
import {ManualOppoContext} from '../index';
import {IOrganisationOpportunitySettingsResponse} from '@joc/api-gateway/lib/api-client';
import Editor from '../../../../shared/components/Editor';

type FormikManualOpportunityMainForm = {
  errors: FormikErrors<ManualOpportunityInitialValuesType>;
  dirty: boolean;
  setFieldValue: (
    field: string,
    value: string | Date | Moment | IGetLocationIdByAddressRequest | null | undefined | Array<string> | number,
    shouldValidate?: boolean | undefined
  ) => void;
  setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void;
  values: any;
  setTouched: any;
  isValid: boolean;
  touched: any;
};

type ManualOpportunityMainFormParentProps = {
  mandatoryFields?: IOrganisationOpportunitySettingsResponse | null;
  isVisibleHashtagField?: boolean;
  setIsVisibleHashtagField?: Dispatch<SetStateAction<boolean>>;
  isUpdate?: boolean;
  isLoading: boolean;
  isStaged?: boolean;
};

export const NoOrganization = 'Private';

const ManualOpportunityMainForm: FC<ManualOpportunityMainFormParentProps> = ({
  isLoading,
  isUpdate,
  isVisibleHashtagField,
  setIsVisibleHashtagField,
  mandatoryFields,
  isStaged = false,
}) => {
  const {startLoading} = useLoading();
  const {t} = useTranslation(['form', 'messages', 'inputs', 'buttons']);
  const {values, isValid, setFieldValue, setTouched, touched}: FormikManualOpportunityMainForm = useFormikContext();
  const [orgError, setOrgError] = useState(false);
  const [options, setOptions] = useState<SelectOption[]>([]);
  const {setSelectedOrgId, isFocusOnOrgId, setIsFocusOnOrgId} = useContext(ManualOppoContext);

  const orgHashtags = useSelector(selectorGetHashtags);
  const volunteerStatus = useSelector((store: Store) => store.userRedux.userData?.volunteerActiveStatus);
  const userData = useSelector(selectorGetUserData);
  const organisations = useSelector((store: Store) => getVolunteerOrganisations(selectorGetUserOrganizations(store)));

  useEffect(() => {
    const defaultOrganization = organisations.find((org) => org.id === userData?.defaultOrganizationId);
    if (defaultOrganization?.schoolId) {
      setFieldValue('organisationId', defaultOrganization.id);
    } else {
      setFieldValue('organisationId', 'null');
    }

    const transformedOrgs: SelectOption[] = getTransformOrg([
      ...(defaultOrganization
        ? [defaultOrganization, ...organisations.filter((org) => org.id !== defaultOrganization.id)]
        : [...organisations]),

      {id: 'null', organizationName: NoOrganization, organizationLogoPath: NoOrganizationIcon},
    ]);
    setOptions(transformedOrgs);
  }, []);

  useEffect(() => {
    if (values.organisationId) {
      getAllOrgHashtags(values.organisationId);
    }

    if (!isUpdate) {
      setFieldValue('tagId', null);
    }

    setTouched(
      Object.keys(touched).reduce((acc, key) => {
        // @ts-ignore
        acc[key] = false;
        return acc;
      }, {}),
      true
    );

    setSelectedOrgId && setSelectedOrgId(values.organisationId);
  }, [values.organisationId]);

  useEffect(() => {
    if (orgError && values.organisationId) {
      setOrgError(false);
    }
  }, [orgError, values.organisationId]);

  useEffect(() => {
    if (isVisibleHashtagField && !values.organisationId) {
      setOrgError(true);
    }

    if (!isVisibleHashtagField) {
      setFieldValue('tagId', null);
    }
  }, [isVisibleHashtagField, values.organisationId]);

  useEffect(() => {
    if (isFocusOnOrgId) {
      const organizationSelect = document.getElementById('organisationId');
      organizationSelect?.scrollIntoView({block: 'center', behavior: 'smooth'});
      organizationSelect?.click();
      setIsFocusOnOrgId(false);
    }
  }, [isFocusOnOrgId]);

  const getAllOrgHashtags = async (orgId: string) => {
    await startLoading(getAllHashtags(orgId));
  };

  const handleChangeVisibleHashtag = (checked: boolean, _value?: string | undefined) => {
    setIsVisibleHashtagField?.(checked);
  };

  const isVolunteerSuspended: boolean = useMemo(
    () => volunteerStatus === VOLUNTEER_ACTIVE_STATUS.SUSPENDED,
    [volunteerStatus]
  );

  return (
    <Form className={styles.form} title={isVolunteerSuspended ? t('messages:youSuspended') : ''}>
      <Field
        name="organisationId"
        isOrganization
        options={options}
        component={Select}
        disabled={isVolunteerSuspended || isUpdate || isStaged}
        label={`${t('form:proposeManOppo.whichSchool')}`}
        placeholder={t('inputs:placeholders.selectOrganization')}
        isRequired
      />
      <Field
        component={Input}
        label={`${t('form:proposeManOppo.whatOppo')}`}
        placeholder={t('inputs:placeholders.oppoName')}
        disabled={isVolunteerSuspended}
        isRequired
        name="opportunityName"
      />
      <Field
        name="opportunityDescription"
        label={`${t('form:opportunity.descriptionReflection')}:`}
        placeholder={t('form:opportunity.describeOpportunity')}
        isShowToolbar={false}
        isLabelBold
        disabled={isVolunteerSuspended}
        isRequired={mandatoryFields?.mandatoryDescription || mandatoryFields?.mandatoryReflection}
        defaultFontSize="18px"
        component={Editor}
      />
      {orgHashtags.length ? (
        <HashtagField
          isRequired={mandatoryFields?.mandatoryTag}
          isVolunteer
          options={orgHashtags}
          isErrorArePresent={orgError}
          isVisibleHashtagField={isVisibleHashtagField || mandatoryFields?.mandatoryTag}
          handleChangeVisibleHashtag={handleChangeVisibleHashtag}
        />
      ) : null}

      <Field
        name="filePathes"
        label={`${t('form:proposeManOppo.photos')}`}
        disabled={isVolunteerSuspended}
        isRequired={mandatoryFields?.mandatoryPhotos}
        hideCustomRequiredError
        component={ItemGallery}
      />
      <div className={styles.form__pickers}>
        <div className={styles.form__pickers_date}>
          <Field
            name="startDate"
            label={t('form:proposeManOppo.date')}
            disabled={isVolunteerSuspended}
            component={DatePicker}
            isRequired
            availableFuture
            availablePast
          />
        </div>
        <div className={styles.form__pickers_time}>
          <Field
            name="endTime"
            label="Total time:"
            disabled={isVolunteerSuspended}
            isRequired={mandatoryFields?.mandatoryDuration}
            component={TimeLimit}
          />
        </div>
      </div>
      <hr className={styles.divider} />
      <div className={styles.title}>{t('form:proposeManOppo.verification')}</div>
      <div className={styles.subtitle}>{t('form:proposeManOppo.verificationDescription')}</div>
      <Field
        name="recipientName"
        label={t('form:proposeManOppo.supervisorName')}
        placeholder={t('inputs:placeholders.enterName')}
        isRequired={mandatoryFields?.mandatoryRecipientName}
        disabled={isVolunteerSuspended}
        component={Input}
      />
      <Field
        name="recipientEmail"
        label={t('form:email')}
        placeholder={t('inputs:placeholders.email')}
        isRequired={mandatoryFields?.mandatoryRecipientEmail}
        disabled={isVolunteerSuspended}
        component={Input}
      />
      <Field
        name="recipientPhoneNumber"
        label={t('form:phoneNumber')}
        placeholder={t('inputs:placeholders.phone')}
        isRequired={mandatoryFields?.mandatoryRecipientPhone}
        disabled={isVolunteerSuspended}
        component={Input}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <ButtonDefault
          submitType
          classList={['primary', 'lg', 'center']}
          title={t('buttons:button.submit')}
          disabled={!isValid}
        />
      )}
    </Form>
  );
};

export default ManualOpportunityMainForm;
