export const TABLE_HEADER_CLASSNAMES = {
  members: 'members',
  students: 'students',
  past_oppo: 'past_oppo',
  volunteers: 'volunteers',
  smart_group: 'smart_group',
  opportunities: 'opportunities',
  staged_opportunities: 'staged_opportunities',
  inviteStudents: 'inviteStudents',
  inviteOrganizationStudents: 'inviteOrganizationStudents',
  signup_requests: 'signup_requests',
  inviteVolunteers: 'inviteVolunteers',
  smart_group_small: 'smart_group_small',
  possible_volunteers: 'possible_volunteers',
  possible_volunteers_pending: 'possible_volunteers_pending',
  opportunities_without_action: 'opportunities_without_action',
};
