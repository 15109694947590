import {Dispatch, FC, SetStateAction, useState} from 'react';
import styles from '../InvitePopup.module.scss';
import InputImportList from '../../../../../../shared/components/FormInputs/InputImportList';
import ButtonDefault from '../../../../../../shared/components/Buttons/ButtonsDefault';
import {overTariffCount} from '../../../../../../core/functions/overTariffCount';
import {Link} from 'react-router-dom';
import {urls} from '../../../../../../core/appUrls';
import InvitedVolunteersTable from '../InvitedVolunteersTable';
import Loader from '../../../../../../shared/components/Loader';
import {invitePopupSubmitClickHandler} from '../../../../../../core/functions/submitClickHandler';
import PopupConfirmation from '../../../../../../shared/components/PopupConfirmation';
import {useSelector} from 'react-redux';
import {Store} from '../../../../../../redux/root';
import {IInviteUserRequest} from '@joc/api-gateway';
import {useTranslation} from 'react-i18next';
import {selectorPayGateStatus} from '../../../../../../redux/organization-service/selector';
import {InvitedVolunteers} from '../AddByList';

type InviteByListProps = {
  confirmationSubmitClickHandler: () => void;
  isShowConfirmationChange: boolean;
  setIsShowConfirmationChange: Dispatch<SetStateAction<boolean>>;
  setIsSuccess: (isSuccess: boolean) => void;
  setIsEmpty: (isEmpty: boolean) => void;
};

const InviteByList: FC<InviteByListProps> = ({
  confirmationSubmitClickHandler,
  isShowConfirmationChange,
  setIsShowConfirmationChange,
  setIsSuccess,
  setIsEmpty,
}) => {
  const [isShowConfirmClear, setIsShowConfirmClear] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [customError, setCustomError] = useState<string>();
  const [invitedVolunteers, setVolunteers] = useState<Array<InvitedVolunteers>>();
  const [validationErrorsCount, setValidationErrorsCount] = useState(0);

  const volunteersTotal = useSelector((store: Store) => store.dashboardRedux.main.volunteersTotal);
  const volunteersCountByTariff = useSelector((store: Store) => store.dashboardRedux.main.volunteersCountByTariff);
  const orgId = useSelector((store: Store) => store.organizationRedux.organizationInfo?.id);
  const schoolId = useSelector((store: Store) => store.organizationRedux.organizationInfo?.schoolId);
  const payGateStatus = useSelector(selectorPayGateStatus);

  const {t} = useTranslation(['inviteColleagues', 'buttons']);

  const handleInvitedVolunteers = (invitedVolunteers?: Array<IInviteUserRequest>) => {
    setIsEmpty(!invitedVolunteers?.length);
    setVolunteers(invitedVolunteers);
  };

  return (
    <div className={styles.list}>
      <div className={styles.list__wrapper}>
        <div className={styles.list__nav}>
          <InputImportList
            setStateValue={handleInvitedVolunteers}
            listLength={invitedVolunteers?.length}
            invitedUserType={!!schoolId ? 'student' : 'volunteer'}
            exampleFilePath={
              !!schoolId
                ? `${process.env.PUBLIC_URL}/assets/documents/inviteStudentsTemplate.xlsx`
                : `${process.env.PUBLIC_URL}/assets/documents/inviteVolunteersTemplate.xlsx`
            }
            errorCount={validationErrorsCount}
            setErrorsCount={setValidationErrorsCount}
          />
          {!!invitedVolunteers?.length && (
            <ButtonDefault
              classList={['secondary']}
              title={t('buttons:button.clearAll')}
              clickHandler={() => setIsShowConfirmClear(true)}
            />
          )}
        </div>
        {!!invitedVolunteers?.length &&
          !!overTariffCount(volunteersTotal, volunteersCountByTariff, payGateStatus, invitedVolunteers.length) && (
            <>
              <div className={styles.backRed}>
                {t('inviteColleagues:messageEndTariff')}
                <br />
                {t('inviteColleagues:messagePlease')}
                <Link to={urls.tariffPlans}>{t('inviteColleagues:tariffPlans')}</Link>
              </div>
            </>
          )}
        {!!invitedVolunteers?.length && (
          <InvitedVolunteersTable
            overTariffStartIndex={overTariffCount(
              volunteersTotal,
              volunteersCountByTariff,
              payGateStatus,
              invitedVolunteers.length
            )}
            volunteers={invitedVolunteers}
          />
        )}
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        !!invitedVolunteers?.length && (
          <ButtonDefault
            disabled={
              !!overTariffCount(volunteersTotal, volunteersCountByTariff, payGateStatus, invitedVolunteers.length) ||
              !!customError ||
              !!validationErrorsCount
            }
            submitType={false}
            classList={['primary', 'lg']}
            title={t('buttons:button.next')}
            clickHandler={() =>
              invitePopupSubmitClickHandler(invitedVolunteers, orgId, setIsLoading, setIsSuccess, setCustomError)
            }
          />
        )
      )}
      {isShowConfirmationChange && (
        <PopupConfirmation
          confirmClickHandler={() => {
            confirmationSubmitClickHandler();
            setIsShowConfirmationChange(false);
            handleInvitedVolunteers([]);
          }}
          cancelClickHandler={() => setIsShowConfirmationChange(false)}
          setIsShowPopup={setIsShowConfirmationChange}
        />
      )}
      {isShowConfirmClear && (
        <PopupConfirmation
          confirmClickHandler={() => {
            handleInvitedVolunteers([]);
            setIsShowConfirmClear(false);
          }}
          cancelClickHandler={() => setIsShowConfirmClear(false)}
          setIsShowPopup={setIsShowConfirmClear}
        />
      )}
    </div>
  );
};

export default InviteByList;
