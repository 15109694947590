import {createContext, Dispatch, FC, Fragment, SetStateAction, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import {Formik} from 'formik';
import moment from 'moment';
import {Typography} from '@mui/material';

// redux
import {useSelector} from 'react-redux';
import useThunkDispatch from '../../../hooks/useThunkDispatch';
import {Store} from 'redux/root';
import {createManualOpportunity} from 'redux/opportunities-service/action';
import {resetError, setError} from 'redux/error-service/action';
import {getOpportunitySettingsForVolunteer} from '../../../redux/organization-service/actions';
import {
  selectorGetOppoSettingsForVolunteer,
  selectorLoadedStatusOppoSettingsForVolunteer,
} from '../../../redux/organization-service/selector';
import {selectorGetHashtags} from '../../../redux/hashtag-service/selectors';
import {selectorGetUserData, selectorGetUserOrganizations} from '../../../redux/user-service/selector';
import {getVolunteerOrganisations} from '../../../components/Organization/InputSearchOrg/helpers';

// urls
import {urls} from 'core/appUrls';

// validation
import {dynamicManualOppoValidation, opportunityManualValidationSchema} from 'core/validation';

// constants
import {OPPORTUNITIES_INITIAL_VALUES_MANUAL} from 'core/constants';

//components
import ResponseFailure from 'shared/components/ResponseFailure';
import Appeal from 'shared/components/Appeal/DefaultRenderer';
import ArrowBackLink from 'shared/components/ArrowBackLink';
import CongratsPopup from 'components/Organization/CongratsPopup';
import ManualOpportunityMainForm from './ManualOpportunityMainForm';
import NewDialog from '../../../shared/components/NewUI/Dialog';

// types
import {
  ICreateManualOpportunityRequest,
  IOrganisationOpportunitySettingsResponse,
  OPPORTUNITY_TYPES,
} from '@joc/api-gateway/lib/api-client';
import {ManualOpportunityInitialValuesType} from 'core/types';

//styles
import styles from './CreateManualOppoV.module.scss';

type ManualOppoContextType = {
  organizationId: string | null;
  setSelectedOrgId: Dispatch<SetStateAction<string>>;
  isFocusOnOrgId: boolean;
  setIsFocusOnOrgId: Dispatch<SetStateAction<boolean>>;
};

export const ManualOppoContext = createContext<ManualOppoContextType>({} as ManualOppoContextType);

const CreateManualOppoV: FC = () => {
  const history = useHistory();
  const dispatch = useThunkDispatch();
  const {t} = useTranslation(['errors', 'buttons', 'messages', 'form']);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataForSubmitConfirm, setDataForSubmitConfirm] = useState<ManualOpportunityInitialValuesType | null>(null);

  const [isVisibleHashtagField, setIsVisibleHashtagField] = useState(false);
  const [isFocusOnOrgId, setIsFocusOnOrgId] = useState(false);

  const [organizationId, setSelectedOrgId] = useState<string>('');

  const error = useSelector((store: Store) => store.errorRedux.error);
  const userVolunteerId = useSelector((store: Store) => store.userRedux.userData?.volunteerId);
  const oppoSettingsForVolunteer = useSelector(selectorGetOppoSettingsForVolunteer);
  const isLoadedOppoSettingsForVolunteer = useSelector(selectorLoadedStatusOppoSettingsForVolunteer);
  const userId = useSelector((store: Store) =>
    store.userRedux.userData?.id ? +store.userRedux.userData?.id : undefined
  );

  const userData = useSelector(selectorGetUserData);
  const organisations = useSelector((store: Store) => getVolunteerOrganisations(selectorGetUserOrganizations(store)));

  const orgHashtags = useSelector(selectorGetHashtags);

  const dataPreset = {
    ...OPPORTUNITIES_INITIAL_VALUES_MANUAL,
    startDate: new Date(),
    endDate: new Date(),
    startTime: moment().add(1, 'hours').format('HH:mm'),
  };

  const formikRef = useRef(null);
  useEffect(() => {
    // @ts-ignore
    formikRef?.current?.validateForm();
  }, [organizationId]);

  const [initValues, setInitValues] = useState({
    ...dataPreset,
    opportunityTypes: OPPORTUNITY_TYPES.MANUAL,
    volunteerId: userVolunteerId ? +userVolunteerId : 0,
    user: userId ? +userId : 0,
  });

  useEffect(() => {
    const values = localStorage.getItem('manual');
    if (values) setInitValues(JSON.parse(values));
  }, [error.state]);

  const submitClickHandler = async (values: ManualOpportunityInitialValuesType): Promise<void> => {
    if (!userVolunteerId) throw new Error(t('errors:cantFindVolunteerIdRefresh'));
    if (!userId) throw new Error(t('errors:cantFindUserIdRefresh'));

    const {organisationId: orgId} = values;

    const defaultOrganization = organisations.find((org) => org.id === userData?.defaultOrganizationId);

    if (defaultOrganization?.schoolId && orgId === 'null') {
      setDataForSubmitConfirm(values);
    } else {
      await createOpportunity(values);
    }
  };

  const createOpportunity = async (values: ManualOpportunityInitialValuesType) => {
    setIsLoading(true);
    try {
      setIsFocusOnOrgId(false);
      const {organisationId, ...valuesToCreate} = values;
      const body = document.createElement('body');
      body.innerHTML = values.opportunityDescription;

      await dispatch(
        createManualOpportunity({
          ...valuesToCreate,
          organisationId: organisationId === 'null' ? undefined : organisationId,
          opportunityDescription: body.outerHTML,
        } as unknown as ICreateManualOpportunityRequest)
      );

      setIsSubmitted(true);
    } catch (err: any) {
      dispatch(setError(err?.response?.message || err.message, err?.response?.code || err.code));
    } finally {
      setIsLoading(false);
    }
  };

  const handlerResetError = () => dispatch(resetError());

  useEffect(() => {
    if (!oppoSettingsForVolunteer.length && !isLoadedOppoSettingsForVolunteer) {
      dispatch(getOpportunitySettingsForVolunteer());
    }
  }, [oppoSettingsForVolunteer]);

  const mandatoryFields = useMemo<IOrganisationOpportunitySettingsResponse | null>(() => {
    if (oppoSettingsForVolunteer.length && organizationId) {
      const orgSettings = oppoSettingsForVolunteer.find(
        (settings) => Number(settings.opportunitySettings?.organisationId) === Number(organizationId)
      )?.opportunitySettings;

      if (orgSettings) {
        return Object.assign(
          {},
          ...(Object.keys(orgSettings) as Array<keyof IOrganisationOpportunitySettingsResponse>).map((key) => {
            if (key === 'mandatoryTag') {
              return {[key]: orgSettings[key] && !!orgHashtags.length && organizationId};
            }

            return {[key]: orgSettings[key]};
          })
        );
      }
    }

    return null;
  }, [oppoSettingsForVolunteer, organizationId, orgHashtags]);

  const validation = useMemo(() => {
    return dynamicManualOppoValidation(mandatoryFields || ({} as unknown as IOrganisationOpportunitySettingsResponse));
  }, [mandatoryFields]);

  const handleSubmitConfirm = async () => {
    if (dataForSubmitConfirm) {
      await createOpportunity(dataForSubmitConfirm);
      setDataForSubmitConfirm(null);
    }
  };

  const handleGoBackConfirm = () => {
    setIsFocusOnOrgId(true);
    setDataForSubmitConfirm(null);
  };

  return (
    <Fragment>
      <ArrowBackLink parentClassName={styles.nav} />
      {error.state ? (
        <ResponseFailure
          message={error.message}
          buttonTitle={t('errors:checkAndTryAgain')}
          buttonClickHandler={handlerResetError}
        />
      ) : isSubmitted ? (
        <CongratsPopup
          buttonTitle={t('buttons:button.done')}
          subtitle={t('messages:createdManualChesedOppo')}
          doneButtonClickHandler={() => history.push(urls.volMyFeed)}
        />
      ) : (
        <Appeal styleContact>
          <div className={styles.title}>{t('form:proposeManOppo.title')}</div>
          <div className={styles.form__container}>
            <ManualOppoContext.Provider value={{organizationId, setSelectedOrgId, isFocusOnOrgId, setIsFocusOnOrgId}}>
              <Formik
                innerRef={formikRef}
                enableReinitialize
                initialValues={initValues}
                validationSchema={validation}
                onSubmit={submitClickHandler}
              >
                <ManualOpportunityMainForm
                  isLoading={isLoading}
                  isVisibleHashtagField={isVisibleHashtagField}
                  setIsVisibleHashtagField={setIsVisibleHashtagField}
                  mandatoryFields={mandatoryFields}
                />
              </Formik>
              <NewDialog
                open={!!dataForSubmitConfirm}
                onClose={handleGoBackConfirm}
                title="Important"
                content={
                  <>
                    <Typography
                      sx={{
                        fontFamily: '"Plus Jakarta Sans", sans-serif',
                        fontSize: 14,
                        fontWeight: 400,
                        lineHeight: '24px',
                      }}
                    >
                      This is a <b>Private Opportunity</b> and won’t count towards your school’s hours.
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: '"Plus Jakarta Sans", sans-serif',
                        fontSize: 14,
                        fontWeight: 400,
                        lineHeight: '24px',
                        marginTop: '16px',
                      }}
                    >
                      If you would like this to be counted towards your school’s hour, please <br />
                      <b>go back</b> and select your school in the <b>{t('form:proposeManOppo.whichSchool')}</b> drop
                      down.
                    </Typography>
                  </>
                }
                primaryButtonText="I understand"
                onPrimaryClick={handleSubmitConfirm}
                secondaryButtonText="Go back"
              />
            </ManualOppoContext.Provider>
          </div>
        </Appeal>
      )}
    </Fragment>
  );
};

export default CreateManualOppoV;
