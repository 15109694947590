//react
import {FC, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
// types
import {IOpportunityResponse} from '@joc/api-gateway';
// hooks
import {PopupsState} from 'hooks/useTableActionsPopup';
// constants
import {LINKS_URLS, LinkCopiedSuccessfully} from 'core/constants';
// components
import PopupConfirmation from 'shared/components/PopupConfirmation';
import SnackbarSuccess from 'shared/components/Snackbars/SnackbarSuccess';
import RepeatedActionTypeModal from 'shared/components/RepeatedActionTypeModal';
import PopupMain from 'components/Organization/Opportunities/OpportunityPopups/PopupMain';
import PopupUpdate from 'components/Organization/Opportunities/OpportunityPopups/PopupUpdate';
import {EditManualOppPopup} from 'components/Organization/EditMannualOppoPopup/EditManualOppoPopup';
import ShareSocialPopup from 'components/Volunteer/Opportunities/OpportunitiesList/ShareLinks/ShareSocialPopup';
import ManualOpportunityDetailsPopup from 'components/Organization/Opportunities/OpportunityPopups/ManualOpportunityDetailsPopup/ManualOpportunityDetailsPopup';
import PopupStaged from '../../Organization/Opportunities/OpportunityPopups/PopupStaged';

interface ITableActionsPopupsProps {
  popupsState: PopupsState;
  opportunity: IOpportunityResponse;
  generalTabClickHandler?: () => void;
  deleteConfirmClickHandler: () => void;
  popupConfirmationClickHandler: () => void;
  closeDeleteTypeModalClickHandler: () => void;
  isStagedTab?: boolean;
}

export const TableActionsPopups: FC<ITableActionsPopupsProps> = ({
  opportunity,
  popupsState,
  generalTabClickHandler,
  deleteConfirmClickHandler,
  popupConfirmationClickHandler,
  closeDeleteTypeModalClickHandler,
  isStagedTab = false,
}) => {
  const {t} = useTranslation(['buttons', 'messages']);

  const linkForCopying = useMemo(
    () => `${process.env.REACT_APP_MAIN_URL}${LINKS_URLS.feedViewLink}/${opportunity.id}`,
    [opportunity]
  );

  return (
    <>
      {popupsState.edit.popupState && (
        <EditManualOppPopup
          opportunityData={opportunity}
          generalTabClickHandler={generalTabClickHandler}
          setIsOpenPopup={popupsState.edit.popupSetState}
        />
      )}
      {popupsState.update.popupState && (
        <PopupMain setIsShowPopup={popupsState.update.popupSetState}>
          <PopupUpdate
            createMode={false}
            opportunityData={opportunity}
            setIsShowUpdateOpportunityPopup={popupsState.update.popupSetState}
          />
        </PopupMain>
      )}
      {popupsState.editStaged.popupState && (
        <PopupStaged
          setIsShowPopup={popupsState.editStaged.popupSetState}
          organisationId={opportunity.organisation?.id}
          stagedOpportunity={opportunity}
          isStagedTab={isStagedTab}
        />
      )}
      {popupsState.manual.popupState && (
        <PopupMain setIsShowPopup={popupsState.manual.popupSetState}>
          <ManualOpportunityDetailsPopup
            createMode={false}
            opportunityData={opportunity}
            setIsShowUpdateOpportunityPopup={popupsState.manual.popupSetState}
          />
        </PopupMain>
      )}
      {popupsState.confirmation.popupState && (
        <PopupConfirmation
          text={t('messages:wantToDeleteOppo')}
          confirmClickHandler={popupConfirmationClickHandler}
          setIsShowPopup={popupsState.confirmation.popupSetState}
          cancelClickHandler={() => popupsState.confirmation.popupSetState(false)}
        />
      )}
      {popupsState.delete.popupState && (
        <RepeatedActionTypeModal
          isDelete
          handleSubmit={deleteConfirmClickHandler}
          isModalOpen={popupsState.delete.popupState}
          closePopupClickHandler={closeDeleteTypeModalClickHandler}
        />
      )}
      {popupsState.share.popupState && (
        <ShareSocialPopup
          opportunity={opportunity}
          link={linkForCopying}
          setIsShowPopup={popupsState.share.popupSetState}
          setIsOpenSnackbar={popupsState.snackbar.popupSetState}
        />
      )}
      {popupsState.snackbar.popupState && (
        <SnackbarSuccess
          text={LinkCopiedSuccessfully}
          isOpenSnackbar={popupsState.snackbar.popupState}
          setIsOpenSnackbar={popupsState.snackbar.popupSetState}
        />
      )}
    </>
  );
};
