import i18n from 'components/translate';

const tableHeadersT = i18n.getFixedT(null, 'tableHeaders');

export const TABLE_HEADERS = {
  lastActivity: tableHeadersT('lastActivity'),
  volunteerStatus: tableHeadersT('volunteerStatus'),
  studentStatus: tableHeadersT('studentStatus'),
  completedHours: tableHeadersT('completedHours'),
  organization: tableHeadersT('organization'),
  hashtag: tableHeadersT('hashtag'),
  avatar: tableHeadersT('avatar'),
  logo: tableHeadersT('logo'),
  fullName: tableHeadersT('fullName'),
  email: tableHeadersT('email'),
  phoneNumber: tableHeadersT('phoneNumber'),
  age: tableHeadersT('age'),
  gender: tableHeadersT('gender'),
  location: tableHeadersT('location'),
  forms: tableHeadersT('forms'),
  monthly: tableHeadersT('monthly'),
  total: tableHeadersT('total'),
  select: tableHeadersT('select'),
  chesedName: tableHeadersT('chesedName'),
  date: tableHeadersT('date'),
  expirationDate: tableHeadersT('expirationDate'),
  start: tableHeadersT('start'),
  end: tableHeadersT('end'),
  coordinator: tableHeadersT('coordinator'),
  volunteers: tableHeadersT('volunteers'),
  students: tableHeadersT('students'),
  grade: tableHeadersT('grade'),
  timeSpend: tableHeadersT('timeSpend'),
  timeSpendAbbreviation: tableHeadersT('timeSpendAbbreviation'),
  totalTimeSpendMonthly: tableHeadersT('totalTimeSpendMonthly'),
  gradeId: tableHeadersT('gradeId'),
  role: tableHeadersT('role'),
  duration: tableHeadersT('duration'),
  yearTimeSpend: tableHeadersT('yearTimeSpend'),
  amountOppo: tableHeadersT('amountOpportunities'),
};
