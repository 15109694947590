import {FC} from 'react';
import cx from 'classnames';
import Tooltip from '@mui/material/Tooltip';
//icons
import {ReactComponent as InfoIcon} from 'assets/icons/info.svg';
//helpers
import {CommonProps} from './helpers';
//styles
import styles from './TableHeadCell.module.scss';

type TableHeadCellPropsType = CommonProps;

const TableHeadCell: FC<TableHeadCellPropsType> = ({text, abbreviation, itemClassName, children}) => (
  <div className={cx(styles.main, itemClassName && styles[itemClassName])}>
    {children || <span className={styles.text}>{text}</span>}
    {abbreviation ? (
      <Tooltip title={abbreviation}>
        <InfoIcon className={styles.info__icon} />
      </Tooltip>
    ) : null}
  </div>
);

export default TableHeadCell;
