import {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
//constants
import {CHECK_LANG} from 'core/constants';
//types
import {FieldBaseProps} from 'core/types';
//components
import FieldWrapper from 'shared/inputs/FieldWrapper';
import {createTheme, Slider, ThemeProvider} from '@material-ui/core';
import {Overrides} from '@material-ui/core/styles/overrides';
//styles
import styles from './index.module.scss';

const materialTheme = createTheme({
  overrides: {
    MuiSlider: {
      root: {
        color: '#214ba5',
      },
    },
  } as Overrides,
});

type SliderRangeProps = FieldBaseProps<{
  min: number;
  max: number;
}>;

const SliderRange: FC<SliderRangeProps> = ({min, max, ...fieldBaseProps}) => {
  const {field, form} = fieldBaseProps;
  const {setFieldValue} = form;

  const [isMaxValue, setIsMaxValue] = useState(false);

  const {t, i18n} = useTranslation('form');

  const locale = i18n.language;

  return (
    <FieldWrapper {...fieldBaseProps}>
      <div className={styles.main}>
        <span className={styles.slider__value}>
          {locale !== CHECK_LANG
            ? `${field.value} ${t('filter.mi')}`
            : `${field.value === undefined ? 0 : Math.floor(field.value * 1.61)} ${t('filter.km')}`}
        </span>
        <ThemeProvider theme={materialTheme}>
          <Slider
            min={min}
            max={max}
            value={field.value}
            onChange={(e, value) => {
              if (value === max) setIsMaxValue(true);
              setIsMaxValue(false);
              setFieldValue(field.name, value as number);
            }}
            classes={{
              root: styles.slider,
              rail: styles.slider__rail,
              thumb: cx(styles.slider__thumb, isMaxValue && styles.slider__thumb_max),
              track: styles.slider__track,
            }}
          />
        </ThemeProvider>
      </div>
    </FieldWrapper>
  );
};

export default SliderRange;
