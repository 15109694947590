import {Dispatch, FC, SetStateAction, useState} from 'react';
import {useSelector} from 'react-redux';
import {Field, Form, useFormikContext} from 'formik';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';

// components
import Input from 'shared/inputs/Input';
import Editor from 'shared/components/Editor';
import {HashtagField} from 'shared/components/HashtagField';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import UploadPhoto from '../../../Volunteer/UploadPhoto';
import SmartGroupSelect from 'shared/components/SelectDropdown/SmartGroupSelect';
import {DatePicker} from 'shared/components/Pickers/NewUI/DatePicker';
import Loader from 'shared/components/Loader';

// redux
import {selectorGetHashtags} from 'redux/hashtag-service/selectors';

// types
import {ICreateStagedOpportunityRequest} from '@joc/api-gateway';

// styles
import styles from '../OpportunityForm/OpportunityMainForm/OpportunityMainForm.module.scss';

type StagedOpportunityFormProps = {
  isVisibleHashtagField: boolean;
  setIsVisibleHashtagField: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
};

const StagedOpportunityForm: FC<StagedOpportunityFormProps> = ({
  isVisibleHashtagField,
  setIsVisibleHashtagField,
  isLoading,
}) => {
  const {t} = useTranslation(['form', 'buttons', 'inputs', 'statistics']);
  const {dirty, setFieldValue} = useFormikContext<ICreateStagedOpportunityRequest>();
  const orgHashtags = useSelector(selectorGetHashtags);

  const handleChangeVisibleHashtag = (checked: boolean, _value?: string | undefined) => {
    setIsVisibleHashtagField(checked);
    setFieldValue('tagId', checked ? _value : undefined).finally();
  };

  return (
    <Form className={cx(styles.form && styles.buttons)}>
      <Field name="imagePath" parentClassName={styles.upload_photo} big opportunityPhoto component={UploadPhoto} />
      <Field
        name="opportunityName"
        label={`${t('inputs:placeholders.name')}:`}
        placeholder={t('inputs:placeholders.enterName')}
        isLabelBold
        component={Input}
      />

      <Field
        name="opportunityDescription"
        label={`${t('form:opportunity.description')}:`}
        placeholder={t('form:opportunity.describeOpportunity')}
        isShowToolbar={false}
        isLabelBold
        defaultFontSize="18px"
        component={Editor}
      />

      <HashtagField
        options={orgHashtags}
        isVisibleHashtagField={isVisibleHashtagField}
        handleChangeVisibleHashtag={handleChangeVisibleHashtag}
        isDisableHeight
      />

      <div className={styles.form__pickers_dateSingle}>
        <Field name="endDate" label="Expiration Date" availableFuture isLabelBold disablePast component={DatePicker} />
      </div>

      <Field
        name="smartGroupId"
        label={t('form:opportunity.notifySmartGroup')}
        isLabelBold
        component={SmartGroupSelect}
      />

      {isLoading ? (
        <Loader />
      ) : (
        <ButtonDefault
          submitType
          classList={['primary', 'lg', 'publish']}
          title={t('buttons:button.publish')}
          disabled={!dirty}
        />
      )}
    </Form>
  );
};

export default StagedOpportunityForm;
