import {FC, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
//redux
import {deleteOpportunity, getOpportunitiesCount} from 'redux/opportunities-service/action';
import {selectorGetOrgId} from 'redux/organization-service/selector';
import {useSelector} from 'react-redux';
import {Store} from 'redux';
import {setError} from 'redux/error-service/action';
//hooks
import {useTableActionsPopup} from 'hooks/useTableActionsPopup';
import useThunkDispatch from 'hooks/useThunkDispatch';
//types
import {
  GetVolunteerByIdResponse,
  IOpportunityResponse,
  OPPORTUNITY_TYPES,
  RECURRENCE_POSSIBLE_UPDATE,
} from '@joc/api-gateway';
//components
import OpportunityBadges from 'shared/components/Table/Badges/OpportunityBadges';
import {TableActionsPopups} from 'components/TableActionsPopup/TableActionsPopups';
//context
import {useOpportunitiesContext} from 'pages/Organization/Opportunities/context';
//icons
import {ReactComponent as ManualOppo} from 'assets/icons/heart.svg';
import {ReactComponent as PrevoiusCompleted} from 'assets/icons/previous-completed.svg';

//styles
import styles from '../CellDefault.module.scss';

type OpportunityNameCellProps = {
  opportunityName: string;
  opportunityType: OPPORTUNITY_TYPES;
  isVerified: boolean;
  volunteers?: GetVolunteerByIdResponse[];
  opportunityNameClass?: string;
  adminId?: string;
  opportunity?: IOpportunityResponse | null;
  isStagedTab?: boolean;
};

const OpportunityNameCell: FC<OpportunityNameCellProps> = ({
  opportunityType,
  opportunityName,
  isVerified,
  opportunityNameClass,
  adminId,
  volunteers,
  opportunity,
  isStagedTab = false,
}) => {
  const dispatch = useThunkDispatch();

  const {generalTabClickHandler} = useOpportunitiesContext();

  const orgId = useSelector((store: Store) => selectorGetOrgId(store as any));

  const {
    i18n: {language},
    t,
  } = useTranslation(['common']);

  const isAdmin = useMemo(
    () => !!(volunteers?.length === 1 && volunteers[0].userId !== adminId),
    [volunteers, adminId]
  );

  const isManualOpportunity = useMemo(() => opportunityType === OPPORTUNITY_TYPES.MANUAL, [opportunityType]);
  const isStagedOpportunity = useMemo(() => opportunityType === OPPORTUNITY_TYPES.STAGED, [opportunityType]);

  const {
    popupsState,
    popupsActions: {
      handleOpenManualPopup,
      handleEditStagedOpp,
      handleOpenDeleteTypeModal,
      handleCloseDeleteTypeModal,
      handleOpenUpdatePopup,
    },
  } = useTableActionsPopup(() => {});

  const handleDeleteOppo = async (updateType?: RECURRENCE_POSSIBLE_UPDATE) => {
    if (opportunity?.id) {
      try {
        await dispatch(deleteOpportunity(opportunity.id, updateType));
        if (orgId) await dispatch(getOpportunitiesCount(orgId.toString()));
        if (!!updateType) window.location.reload();
        popupsState.confirmation.popupSetState(false);
      } catch (error) {
        dispatch(setError(error?.response?.message || error.message, error.code));
      }
    }
  };

  const handleOpenConfirmModal = () => {
    !!opportunity?.repeatableOpportunity ? handleOpenDeleteTypeModal() : handleDeleteOppo();
  };

  return (
    <div className={opportunityNameClass}>
      {isVerified && <OpportunityBadges language={language} />}

      <div className={styles.chesedName}>
        {isManualOpportunity ? (
          <>
            <div className={styles.mark__wrapper}>
              <div className={styles.manual__mark}>
                {!opportunity?.isInitial && <ManualOppo height={20} width={20} />}
                {opportunity?.isInitial && (
                  <PrevoiusCompleted height={25} width={25} className={styles.previous_completed_mark} />
                )}
              </div>

              {isAdmin && <p className={styles.admin__mark}>{t('common:admin')}</p>}
            </div>

            <span title={opportunityName} onClick={handleOpenManualPopup} className={styles.opportunity__name}>
              {opportunityName}
            </span>
          </>
        ) : (
          <span
            className={styles.opportunity__name}
            onClick={isStagedOpportunity ? handleEditStagedOpp : handleOpenUpdatePopup}
            title={opportunityName}
          >
            {opportunityName}
          </span>
        )}
      </div>

      {!!opportunity ? (
        <TableActionsPopups
          opportunity={opportunity}
          popupsState={popupsState}
          deleteConfirmClickHandler={handleDeleteOppo}
          generalTabClickHandler={generalTabClickHandler}
          popupConfirmationClickHandler={handleOpenConfirmModal}
          closeDeleteTypeModalClickHandler={handleCloseDeleteTypeModal}
          isStagedTab={isStagedTab}
        />
      ) : null}
    </div>
  );
};

export default OpportunityNameCell;
