import * as Yup from 'yup';
import {REGEX_TITLE} from 'core/constants';
import i18n from 'components/translate';

const validationT = i18n.getFixedT(null, 'validation');

export const stagedOpportunityNameSchema = Yup.string()
  .transform((value) => (value.trim().length ? value : ''))
  .required(validationT('stagedOpportunityNameSchema.required'))
  .min(2, validationT('stagedOpportunityNameSchema.min'))
  .max(100, validationT('stagedOpportunityNameSchema.max'))
  .matches(REGEX_TITLE, validationT('useAZSymbolsNumbers'));

export const stagedOpportunityDescriptionSchema = Yup.string()
  .transform((value) =>
    value.replaceAll('&nbsp;', '').replaceAll('<p>', '').replaceAll('</p>', '').trim().length ? value : ''
  )
  .test('required', validationT('stagedOpportunityDescriptionSchema.required'), (value) => {
    if (value) {
      const elem = document.createElement('div');
      elem.innerHTML = value;
      return !!(value && elem.innerText.length);
    }
    return false;
  });

export const stagedOpportunityValidationSchema = Yup.object().shape({
  opportunityName: stagedOpportunityNameSchema,
  opportunityDescription: stagedOpportunityDescriptionSchema,
});
