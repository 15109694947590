// react
import {ChangeEvent, FC, useEffect, useMemo, useState} from 'react';
// mui
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete, {AutocompleteInputChangeReason} from '@material-ui/lab/Autocomplete';
// components
import InputErrorHint from '../InputErrorHint';
import FieldWrapper from 'shared/inputs/FieldWrapper';
// helper
import {getOptionValue} from './helper';
// types
import {FieldBaseProps} from 'core/types';
import {IHashtag} from 'redux/hashtag-service/types';
// icons
import {ReactComponent as HashtagIcon} from 'assets/icons/hashtag.svg';
import {ReactComponent as BlueArrow} from 'assets/image/selects/blue-arrow.svg';
//styles
import styles from './index.module.scss';

const useStyles = makeStyles({
  noOptions: {
    color: '#5b768b',
    fontSize: '18px',
    lineHeight: 'normal',
  },
});

const CustomPaper = (props: any) => {
  return (
    <div className={styles.create__section__list__wrap}>
      <ul {...props} className={styles.create__section__list}>
        {props.children}
      </ul>
    </div>
  );
};

type IHashtagAutocompleteProps = FieldBaseProps<{
  isErrorArePresent: boolean;
  isHashtagField?: boolean;
  noOptionsText?: string;
  placeholder?: string;
  options: IHashtag[];
}>;

export const HashtagAutocomplete: FC<IHashtagAutocompleteProps> = ({
  options,
  placeholder,
  noOptionsText,
  isErrorArePresent,
  isHashtagField,
  ...fieldBaseProps
}) => {
  const classes = useStyles();
  const {field, form} = fieldBaseProps;
  const {setFieldValue} = form;

  const handleSelectOption = (_event: ChangeEvent<{}>, value: string, _reason: AutocompleteInputChangeReason) => {
    const newOptionId = options.find((option) => option.name === value)?.id;

    newOptionId && setFieldValue(field.name, isHashtagField ? Number(newOptionId) : newOptionId);
  };

  return (
    <FieldWrapper {...fieldBaseProps}>
      <div>
        <Autocomplete
          autoComplete
          disableClearable
          value={{
            id: isHashtagField ? Number(field.value) : field.value,
            name:
              options.find((option) =>
                isHashtagField ? Number(option.id) === Number(field.value) : option.id === field.value
              )?.name || '',
          }}
          disabled={isErrorArePresent}
          onInputChange={handleSelectOption}
          noOptionsText={noOptionsText || ''}
          classes={{
            noOptions: classes.noOptions,
          }}
          className={styles.create__section__autocomplete}
          popupIcon={<BlueArrow className={styles.icon} />}
          options={options}
          getOptionLabel={(option) => option.name}
          ListboxComponent={CustomPaper}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder={placeholder || ''}
              className={styles.create__section__textField}
            />
          )}
          renderOption={(option) => {
            return (
              <div key={option.id} className={styles.hashtag__option}>
                <div className={styles.hashtag__option__image}>
                  <HashtagIcon />
                </div>
                <span>{option.name}</span>
              </div>
            );
          }}
        />
        {isErrorArePresent && (
          <div className={styles.error}>
            <InputErrorHint errorText="Please select Organization/School" />
          </div>
        )}
      </div>
    </FieldWrapper>
  );
};
