import {ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useRef, useState, MouseEvent, useMemo} from 'react';
import cx from 'classnames';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import {string} from 'yup';
import moment from 'moment/moment';

//redux
import {Store} from 'redux/root';
import {notAcceptedInvitesCount, resendInvites} from 'redux/invites-service/action';
import {SET_ERROR} from 'redux/actions-types';
import {selectorGetSchoolId} from 'redux/organization-service/selector';
import {useDispatch, useSelector} from 'react-redux';
//helpers
import {parseVolunteersXlsx} from './helpers';
//validation
import {gradeSchema, phoneNotRequiredNumberSchema} from 'core/validation';
//types
import {IInviteUserRequest} from '@joc/api-gateway';
import {InvitedVolunteers} from '../../../../components/Organization/Volunteers/VolunteersPopups/InvitePopup/AddByList';
//components
import PopupCongrats from 'components/Organization/Volunteers/VolunteersPopups/PopupCongrats';
//styles
import styles from './InputImportList.module.scss';

type InputImportListProps = {
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  setStateValue?: (list: Array<IInviteUserRequest> | undefined) => void | Promise<void>;
  setErrorsCount?: Dispatch<SetStateAction<number>>;
  fieldName?: string;
  listLength?: number;
  invitedUserType: string;
  exampleFilePath: string;
  addByList?: boolean;
  errorCount?: number;
};

const InputImportList: FC<InputImportListProps> = ({
  setFieldValue,
  setStateValue,
  setErrorsCount,
  listLength,
  invitedUserType,
  fieldName,
  exampleFilePath,
  addByList,
  errorCount,
}: InputImportListProps) => {
  const [file, setFile] = useState<File>();

  const dispatch = useDispatch();

  const {invitesCount, response} = useSelector((store: Store) => store.invitesRedux);
  const schoolId = useSelector(selectorGetSchoolId);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const {t} = useTranslation(['form', 'inviteColleagues', 'validation']);

  useEffect(() => {
    getNotAcceptedVolunteersCount();
  }, []);

  const volunteerValidationSchema = useMemo(
    () =>
      Yup.object({
        firstName: string().required(t('validation:firstNameSchema.required')),
        lastName: string().required(t('validation:lastNameSchema.required')),
        email: string().email(t('validation:emailSchema.email')).required(t('validation:emailSchema.required')),
        birthDate: Yup.date()
          .typeError(t('validation:birthDateSchema.typeError'))
          .max(moment().subtract(5, 'years').toDate(), t('validation:birthDateSchema.max'))
          .min('1900-01-01', 'The date provided is too old. Please enter a more recent date')
          .optional(),
        genderId: Yup.string()
          .test({
            name: 'testGradeId',
            message: 'Gender must be either Male, Female, or left blank.',
            test: (value) => {
              if (!value) return true;
              return Number(value) === 1 || Number(value) === 2;
            },
          })
          .optional(),
        phoneNumber: phoneNotRequiredNumberSchema,
        timeSpend: Yup.number()
          .typeError('Invalid format. Please enter numbers only.')
          .positive('Invalid format. Please enter only positive numbers.')
          .optional(),
        ...(schoolId ? {gradeId: gradeSchema} : {}),
      }),
    [schoolId]
  );

  useEffect(() => {
    if (file) {
      (async () => {
        const parsedVolunteers = await parseVolunteersXlsx(file, schoolId);
        setErrorsCount && setErrorsCount(0);
        let errorCounts = 0;
        if (parsedVolunteers?.length && setStateValue) {
          const validatedVolunteers = parsedVolunteers.map(async (volunteer) => {
            try {
              return await volunteerValidationSchema.validate(volunteer, {abortEarly: false});
            } catch (error) {
              errorCounts += 1;
              const validationErrors: Record<string, string> = {};
              error.inner.forEach((error: any) => {
                if (error.path !== undefined) {
                  validationErrors[error.path] = error.errors[0];
                }
              });
              return {...volunteer, errors: validationErrors};
            }
          });

          console.log(errorCounts);
          setStateValue((await Promise.all(validatedVolunteers)) as Array<InvitedVolunteers>);
          setFieldValue && fieldName && setFieldValue(fieldName, parsedVolunteers);
        }
        setErrorsCount && setErrorsCount(errorCounts);
      })();
    }
  }, [file, schoolId, volunteerValidationSchema]);

  const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.target.files && setFile(Array.from(e.target.files)[0]);
  };

  const resendInvitesVolunteer = async (e: MouseEvent) => {
    e.preventDefault();
    try {
      await dispatch(resendInvites(undefined));
    } catch (error) {
      // setCustomError(error?.response?.message || error.message);
      dispatch({type: SET_ERROR, payload: {state: true, message: error.message}});
    }
  };

  const getNotAcceptedVolunteersCount = async () => {
    try {
      const response = await dispatch(notAcceptedInvitesCount(undefined));
      return response;
    } catch (error) {
      dispatch({type: SET_ERROR, payload: {state: true, message: error.message}});
    }
  };

  const handleUploadClick = async () => {
    setFile(undefined);
    setStateValue && setStateValue([]);
    inputRef.current?.click();
  };

  return (
    <>
      <div className={styles.upload}>
        <span className={styles.upload__text} onClick={handleUploadClick}>
          {t('form:listInput.importAList')}
        </span>
        <input
          ref={inputRef}
          id="upload"
          type="file"
          accept=".csv, application/vnd.oasis.opendocument.spreadsheet, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          onInput={handleUpload}
          // DON'T DROP BELOW!!!
          // @ts-ignore
          // eslint-disable-next-line no-return-assign
          onClick={(e) => (e.target.value = null)}
        />
        <a className={cx(styles.upload__text, styles.downloadTemplate, 'link')} href={exampleFilePath} download>
          {t('form:listInput.downloadListTemplate')}
        </a>

        {!!listLength ? (
          <div className={styles.upload__info}>
            {`${t('inviteColleagues:detected')} ${listLength} ${
              listLength > 1
                ? `${
                    invitedUserType === 'colleague'
                      ? t('inviteColleagues:colleagues')
                      : invitedUserType === 'student'
                      ? t('inviteColleagues:students')
                      : t('inviteColleagues:volunteers')
                  }`
                : `${
                    invitedUserType === 'colleague'
                      ? t('inviteColleagues:colleague')
                      : invitedUserType === 'student'
                      ? t('inviteColleagues:student')
                      : t('inviteColleagues:volunteer')
                  }`
            }${errorCount ? ', ' : ''}`}

            {errorCount ? <span style={{color: '#D00074'}}>{`but ${errorCount} have errors.`}</span> : <></>}
          </div>
        ) : (
          !addByList &&
          !!invitesCount.invitesCount && (
            <div className={cx(styles.upload__text, styles.downloadTemplate)} onClick={resendInvitesVolunteer}>
              {t('form:listInput.resendInvites')}
            </div>
          )
        )}
        {response && <PopupCongrats setIsShowPopup={() => {}} resendInvites />}
      </div>
    </>
  );
};

export default InputImportList;
