import * as XLSX from 'xlsx';
import {InvitedUserWithRole} from '../../../../core/types';
import {UserInfoKeyNamesInExcelSheet, userInfoKeys} from './constants';
import {IInviteUserRequest} from '@joc/api-gateway';

// const checkIsValidItem = async (item: any): Promise<boolean> => {
//   const isValid = await volunteerValidationSchema.validate(item).catch((err) => {
//     err.name; // => 'ValidationError'
//     err.errors; // => [{ key: 'field_too_short', values: { min: 18 } }]
//     console.error(err);
//     console.log('file: index.tsx:61  err:', err);
//   });
//   return !!isValid;
// };

const excelDateToJSDate = (excelDate: string | number): Date => {
  if (typeof excelDate === 'number') {
    const excelEpoch = new Date(Date.UTC(1899, 11, 30)); // December 30, 1899
    const millisecondsPerDay = 24 * 60 * 60 * 1000;

    const convertedDate = new Date(excelEpoch.getTime() + excelDate * millisecondsPerDay);

    return new Date(convertedDate.getUTCFullYear(), convertedDate.getUTCMonth(), convertedDate.getUTCDate());
  }

  return new Date(excelDate);
};

export const parseVolunteersXlsx = (file: File, schoolId?: number) => {
  return new Promise<Array<IInviteUserRequest>>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = e.target?.result;
      const excelData = XLSX.read(data, {type: 'binary'});
      const sheetName = excelData.SheetNames[0];
      const sheet = excelData.Sheets[sheetName];

      const getParsedData = (sheet: XLSX.WorkSheet) => {
        const rowsArray = XLSX.utils.sheet_to_json(sheet, {header: 1}) as Array<Array<string>>;
        const rowIndex = rowsArray.findIndex((row) => row.map((r) => r.replace('*', '')).includes('First Name'));
        const ref = sheet['!ref']!;

        let range = ref;

        if (rowIndex > 0) {
          const [refStart, refEnd] = ref.split(':');
          const [refStartX] = refStart.split('');
          const newRefStart = `${refStartX}${rowIndex + 1}`;
          range = `${newRefStart}:${refEnd}`;
        }

        return XLSX.utils.sheet_to_json(sheet, {range, defval: '', blankrows: false}) as Array<InvitedUserWithRole>;
      };

      let parsedData = getParsedData(sheet);
      const normalizeParsedData = parsedData.map((parsedUserValuesObj) => {
        let normalizedUserValuesObj = {} as InvitedUserWithRole;

        Object.keys(parsedUserValuesObj).forEach((key) => {
          const normalizeKeyName = key
            .split(' ')
            .filter((key) => key !== '-')
            .map((word, idx) => {
              word = word.toLowerCase();
              if (idx !== 0) word = word[0].toUpperCase() + word.slice(1);
              return word;
            })
            .join('');

          normalizedUserValuesObj = {
            ...normalizedUserValuesObj,
            [normalizeKeyName.replace('*', '')]: parsedUserValuesObj[key as keyof InvitedUserWithRole],
          };
        });

        return normalizedUserValuesObj;
      });

      parsedData = normalizeParsedData.map((userValuesObj) => {
        let user = {} as InvitedUserWithRole;

        userInfoKeys.forEach((key) => {
          // @ts-ignore
          if (userValuesObj[UserInfoKeyNamesInExcelSheet[key] as keyof InvitedUserWithRole])
            // @ts-ignore
            user = {...user, [key]: userValuesObj[UserInfoKeyNamesInExcelSheet[key]]};
        });

        if (user.phoneNumber)
          user.phoneNumber = user.phoneNumber[0] === '+' ? user.phoneNumber : `+${user.phoneNumber}`;
        if (user.genderId?.toString().toLowerCase()[0] === 'f') {
          user.genderId = 2;
        }
        if (user.genderId?.toString().toLowerCase()[0] === 'm') {
          user.genderId = 1;
        }
        if (user.studentId) {
          user.studentId = user.studentId?.toString();
        }

        if (user.birthDate) {
          user.birthDate = excelDateToJSDate(user.birthDate as unknown as string);
        }

        if (!schoolId) {
          delete user.gradeId;
        }
        return user;
      });
      // (async () => {
      //   try {
      //     parsedData.forEach(async (item) => await checkIsValidItem(item));
      //   } catch (error) {
      //     throw new Error(error);
      //   }
      // })();

      resolve(parsedData);
    };

    reader.readAsBinaryString(file);
  });
};
