import * as Yup from 'yup';
//types
import {GetLocationIdByAddressRequest, IOrganisationResponse} from '@joc/api-gateway';
//validation
import {
  emailSchema,
  imagePathSchema,
  orgAddressMandatorySchema,
  organizationDescriptionSchema,
  organizationNameShema,
  requiredPhoneNumberSchema,
  websiteSchema,
} from 'core/validation';

export type OrgInfoFormValues = {
  organizationName?: string;
  organizationDescription?: string;
  organizationLogoPath?: string;
  organizationPhoneNumber?: string;
  organizationEmail?: string;
  organizationWebSite?: string;
  address?: GetLocationIdByAddressRequest;
};

export const getInitialValues = (orgData: IOrganisationResponse | null): OrgInfoFormValues => ({
  organizationName: orgData?.organizationName || '',
  organizationDescription: orgData?.organizationDescription || '',
  organizationLogoPath: orgData?.organizationLogoPath || '',
  organizationPhoneNumber: orgData?.organizationPhoneNumber || '',
  organizationEmail: orgData?.organizationEmail || '',
  organizationWebSite: orgData?.organizationWebSite || '',
  address:
    orgData?.address ||
    GetLocationIdByAddressRequest.fromJS({
      countryName: '',
      stateName: '',
      cityName: '',
      streetName: '',
      buildingNumber: '',
      zipCode: '',
      pobox: '',
    }),
});

export const validationSchema = Yup.object().shape({
  organizationName: organizationNameShema,
  organizationLogoPath: imagePathSchema,
  organizationDescription: organizationDescriptionSchema,
  organizationPhoneNumber: requiredPhoneNumberSchema,
  organizationEmail: emailSchema,
  organizationWebSite: websiteSchema,
  address: orgAddressMandatorySchema,
});
