import {FC, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
//redux
import {selectorGetDashboardVolunteers} from 'redux/dashboard-service/selector';
import {getDashboardInfo} from 'redux/dashboard-service/action';
//API
import {API} from 'core/API';
//functions
import {getCurrentLanguageId} from 'core/functions/language';
//types
import {TariffsResponse} from '@joc/api-gateway';
import {LanguageName} from 'core/types';
//helpers
import {getFilteredTariffs, getSortedTariffs} from './helpers';
//components
import TariffPlansItem from './TariffPlansItem';
import Loader from 'shared/components/Loader';
//styles
import styles from './AllTariffs.module.scss';

const AllTariffs: FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [tariffs, setTariffs] = useState<Array<TariffsResponse> | null>(null);

  const volunteersTotal = useSelector(selectorGetDashboardVolunteers);

  const filteredTariffs = useMemo<Array<TariffsResponse> | null>(
    () => getFilteredTariffs(tariffs, volunteersTotal),
    [tariffs, volunteersTotal]
  );

  const {
    i18n: {language},
  } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    loadTariffs();
  }, []);

  const loadTariffs = async () => {
    try {
      setIsLoading(true);
      const idOrganisation = localStorage.getItem('organisationId');

      if (idOrganisation && localStorage.getItem('accessToken')) dispatch(getDashboardInfo(+idOrganisation));

      const currentLanguageId = getCurrentLanguageId(language as LanguageName);
      const newTariffs = await API.getAllTariffs(currentLanguageId.toString());
      const sortedTariffs = getSortedTariffs(newTariffs);

      setTariffs(sortedTariffs);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return isLoading && filteredTariffs?.length ? (
    <Loader loadProps={{disableCenterStyle: true}} />
  ) : (
    <div className={styles.wrapper}>
      {filteredTariffs?.map((item) => (
        <TariffPlansItem key={item.id} tariff={item} />
      ))}
    </div>
  );
};

export default AllTariffs;
