import {Dispatch, FC, SetStateAction, useReducer, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
//formik
import {Formik} from 'formik';
//redux
import {Store} from 'redux/root';
import {selectorGetPossibilityToInvite, selectorPayGateStatus} from 'redux/organization-service/selector';
//urls
import {urls} from 'core/appUrls';
//validation
import {
  studentValidationSchema,
  volunteerSchemaAdding,
  volunteerSchemaStudentAdding,
  volunteerValidationSchema,
} from 'core/validation';
//constants
import {INITIAL_INVITE, INITIAL_INVITE_ADDING} from 'core/constants';
//functions
import {invitePopupSubmitClickHandler} from 'core/functions/submitClickHandler';
//types
import {IInviteUserRequest} from '@joc/api-gateway';
//helpers
import {CurrentTab, getIsSetPreferredTab, getIsShowConfirmation} from './helpers';
//components
import WhiteContainer from 'shared/components/WhiteContainer';
import PopupContainer from 'shared/components/PopupContainer';
import ResponseFailure from 'shared/components/ResponseFailure';
import ButtonTabs from 'components/Organization/Volunteers/VolunteersPopups/InvitePopup/InviteTabs';
import PopupConfirmation from 'shared/components/PopupConfirmation';
import InviteForm from './InviteForm';
import PopupCongrats from '../PopupCongrats';
import InviteLink from './InviteLink';
import {AddByList} from './AddByList';
import InviteByList from './InviteByList';
// component state
import {InvitePopupAction, invitePopupInitial, invitePopupReducer} from './InvitePopup.reducer';

import styles from './InvitePopup.module.scss';

type InvitePopupParentProps = {
  setIsShowPopup: Dispatch<SetStateAction<boolean>>;
};

const InvitePopup: FC<InvitePopupParentProps> = ({setIsShowPopup}) => {
  const [state, dispatcher] = useReducer(invitePopupReducer, invitePopupInitial);

  const [initialFormikValues] = useState<IInviteUserRequest>(INITIAL_INVITE);
  const [initialFormikValuesAdding] = useState<IInviteUserRequest>(INITIAL_INVITE_ADDING);

  const [isShowConfirmationClose, setIsShowConfirmationClose] = useState(false);
  const [isClosePopupConfirmation, setIsClosePopupConfirmation] = useState(false);
  const [isShowConfirmationChange, setIsShowConfirmationChange] = useState(false);

  // Temporary solution with disabling pay gate
  const payGateStatus = useSelector(selectorPayGateStatus);
  const availableInvite = useSelector(selectorGetPossibilityToInvite);

  const orgId = useSelector((store: Store) => store.organizationRedux.organizationInfo?.id);
  const schoolId = useSelector((store: Store) => store.organizationRedux.organizationInfo?.schoolId);

  const {t} = useTranslation(['inviteColleagues', 'buttons']);

  const validationSchema = !!schoolId ? studentValidationSchema : volunteerValidationSchema;
  const validationAddingSchema = !!schoolId ? volunteerSchemaStudentAdding : volunteerSchemaAdding;

  const resetHandler = () => {
    setCustomError(undefined);
  };

  const setIsSuccess = (isSuccess: boolean) => dispatcher({type: InvitePopupAction.SET_IS_SUCCESS, payload: isSuccess});

  const setIsLoading = (isLoading: boolean) => dispatcher({type: InvitePopupAction.SET_IS_LOADING, payload: isLoading});

  const setIsEmpty = (isEmpty: boolean) => dispatcher({type: InvitePopupAction.SET_IS_EMPTY, payload: isEmpty});

  const setCustomError = (customError?: string) =>
    dispatcher({type: InvitePopupAction.SET_CUSTOM_ERROR, payload: customError});

  const setCurrentTab = (currentTab: CurrentTab) =>
    dispatcher({type: InvitePopupAction.SET_CURRENT_TAB, payload: currentTab});

  const setPreferredTab = (preferredTab?: CurrentTab) =>
    dispatcher({type: InvitePopupAction.SET_PREFERRED_TAB, payload: preferredTab});

  const tabClickHandler = (tab: CurrentTab) => () => {
    if (state.currentTab !== tab) {
      const isSetPreferredTab = getIsSetPreferredTab(state.currentTab, state.isEmpty);
      if (isSetPreferredTab) {
        setPreferredTab(tab);
        setIsShowConfirmationChange(true);
      } else {
        setCurrentTab(tab);
      }
    }
  };

  const confirmationSubmitClickHandler = () => {
    if (state.preferredTab) {
      setCurrentTab(state.preferredTab);
      setPreferredTab(undefined);
    }
  };

  const closeButtonParentClickHandler = () => {
    const isShowConfirmation = getIsShowConfirmation(state.currentTab, state.isEmpty);
    if (isShowConfirmation || state.isSuccess) {
      setTimeout(() => setIsShowPopup(false), 200);
    } else {
      setIsShowConfirmationClose(true);
    }
  };

  return (
    <PopupContainer
      isDisablePadding
      setIsShowPopup={setIsShowPopup}
      closeButtonParentClickHandler={closeButtonParentClickHandler}
      isClosePopupByParent={isClosePopupConfirmation}
    >
      {!!state.customError ? (
        <ResponseFailure message={state.customError} buttonClickHandler={resetHandler} isVertical />
      ) : state.isSuccess ? (
        <PopupCongrats
          currentTab={state.currentTab}
          setIsShowPopup={setIsShowPopup}
          isSuccessAdding={state.isSuccess}
        />
      ) : (
        <WhiteContainer
          title={!!schoolId ? t('inviteColleagues:addStudents') : t('inviteColleagues:addVolunteers')}
          parentClassName={
            state.currentTab === CurrentTab.addByList || state.currentTab === CurrentTab.list
              ? styles.reset_padding
              : ''
          }
        >
          {availableInvite || !payGateStatus ? (
            <>
              <div
                style={{
                  margin:
                    state.currentTab === CurrentTab.addByList || state.currentTab === CurrentTab.list ? '0 50px' : 0,
                }}
              >
                <ButtonTabs currentTab={state.currentTab} tabClickHandler={tabClickHandler} />
              </div>
              {state.currentTab === CurrentTab.form && (
                <Formik
                  initialTouched={false}
                  enableReinitialize
                  initialValues={initialFormikValues}
                  // TODO: [JF-1099] [fe] add check to schoolId
                  validationSchema={validationSchema}
                  onSubmit={(values: IInviteUserRequest) =>
                    invitePopupSubmitClickHandler(
                      [{...values, firstName: values.firstName.trim(), lastName: values.lastName.trim()}],
                      orgId,
                      setIsLoading,
                      setIsSuccess,
                      setCustomError
                    )
                  }
                >
                  <InviteForm
                    isLoading={state.isLoading}
                    setIsEmpty={setIsEmpty}
                    isShowConfirmationChange={isShowConfirmationChange}
                    setIsShowConfirmationChange={setIsShowConfirmationChange}
                    confirmationSubmitClickHandler={confirmationSubmitClickHandler}
                  />
                </Formik>
              )}
              {state.currentTab === CurrentTab.link && <InviteLink />}
              {state.currentTab === CurrentTab.adding && (
                <Formik
                  initialTouched={false}
                  enableReinitialize
                  initialValues={initialFormikValuesAdding}
                  validationSchema={validationAddingSchema}
                  onSubmit={(values: IInviteUserRequest) =>
                    invitePopupSubmitClickHandler(
                      [{...values, firstName: values.firstName.trim(), lastName: values.lastName.trim()}],
                      orgId,
                      setIsLoading,
                      setIsSuccess,
                      setCustomError,
                      true
                    )
                  }
                >
                  <InviteForm
                    isShowPassword
                    isLoading={state.isLoading}
                    setIsEmpty={setIsEmpty}
                    isShowConfirmationChange={isShowConfirmationChange}
                    setIsShowConfirmationChange={setIsShowConfirmationChange}
                    confirmationSubmitClickHandler={confirmationSubmitClickHandler}
                    checkEmailExisting={!!schoolId}
                  />
                </Formik>
              )}
              {state.currentTab === CurrentTab.list && (
                <InviteByList
                  confirmationSubmitClickHandler={confirmationSubmitClickHandler}
                  isShowConfirmationChange={isShowConfirmationChange}
                  setIsShowConfirmationChange={setIsShowConfirmationChange}
                  setIsSuccess={setIsSuccess}
                  setIsEmpty={setIsEmpty}
                />
              )}
              {state.currentTab === CurrentTab.addByList && (
                <AddByList
                  confirmationSubmitClickHandler={confirmationSubmitClickHandler}
                  isShowConfirmationChange={isShowConfirmationChange}
                  setIsShowConfirmationChange={setIsShowConfirmationChange}
                  setIsSuccess={setIsSuccess}
                  setIsEmpty={setIsEmpty}
                  setCustomError={setCustomError}
                  customError={state.customError}
                />
              )}
            </>
          ) : (
            <>
              {t('inviteColleagues:messageReached')} <Link to={urls.tariffPlans}>{t('inviteColleagues:here')}</Link>{' '}
              {t('inviteColleagues:messageReturn')}
            </>
          )}
          {isShowConfirmationClose && (
            <PopupConfirmation
              confirmClickHandler={() => {
                setIsClosePopupConfirmation(true);
                setTimeout(() => setIsShowPopup(false), 200);
              }}
              cancelClickHandler={() => setIsShowConfirmationClose(false)}
              setIsShowPopup={setIsShowConfirmationClose}
              isDisableContentMarginTop
            />
          )}
        </WhiteContainer>
      )}
    </PopupContainer>
  );
};

export default InvitePopup;
