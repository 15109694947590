import {useClickOutside} from 'core/customHooks';
import {FC, RefObject, useMemo} from 'react';
import cx from 'classnames';
// constants
import {NoOrganization} from '../../../pages/Volunteers/CreateManualOppoV/ManualOpportunityMainForm';
//assets
import {ReactComponent as WhiteArrow} from 'assets/image/selects/white-arrow.svg';
//types
import {SelectOption} from './types';
//styles
import styles from './styles.module.scss';

type SelectBaseProps = {
  options: Array<SelectOption>;
  value?: SelectOption['value'];
  isOrganization?: boolean;
  onChange: (value: SelectOption['value']) => void;
  id?: string;
  placeholder?: string;
  onBlur?: () => void;
  className?: string;
  disabled?: boolean;
  filterDisabled?: boolean;
};

const SelectBase: FC<SelectBaseProps> = ({
  isOrganization = false,
  options,
  id,
  value,
  onChange,
  placeholder,
  onBlur,
  className,
  disabled,
  filterDisabled,
}) => {
  const {componentRef, isComponentVisible, setIsComponentVisible} = useClickOutside({
    initialVisible: false,
    fieldName: id,
    setFieldTouched: onBlur,
  });

  const title = useMemo(() => options.find((option) => option.value === value)?.title, [options, value]);
  const currentOptions = useMemo(() => options.filter((option) => option.value !== value), [options, value]);

  const titleClickHandler = () => {
    !filterDisabled && setIsComponentVisible(!isComponentVisible);
  };

  const optionClickHandler = (value: SelectOption['value']) => () => {
    onChange(value);
    titleClickHandler();
  };

  return (
    <div
      className={cx(
        styles.select,
        className,
        title && styles.select_selected,
        isComponentVisible && styles.select_active
      )}
      ref={componentRef}
    >
      <div
        id={id}
        className={cx(
          styles.select__head,
          isOrganization && styles.select__head_org,
          disabled && styles.select__head_disabled,
          filterDisabled && styles.select__head_filterDisabled
        )}
        onClick={titleClickHandler}
      >
        <span className={styles.select__title}>{title || placeholder}</span>
        {!filterDisabled && <WhiteArrow className={styles.select__arrow} />}
      </div>
      {isComponentVisible && (
        <div className={styles.select__options}>
          {currentOptions.map((option) => (
            <div key={option.value} className={styles.option} onClick={optionClickHandler(option.value)}>
              {option.imagePath && (
                <img
                  className={styles.option__image}
                  src={
                    isOrganization && option.title !== NoOrganization
                      ? `${process.env.REACT_APP_S3_API}/${option.imagePath}`
                      : option.imagePath
                  }
                  alt={option.title}
                />
              )}
              <span className={styles.option__title}>{option.title}</span>
              {option.helperText && <span className={styles.option__helperText}>{option.helperText}</span>}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
export default SelectBase;
