import qs from 'qs';
//types
import {ICountsOpportunitiesResponse} from '@joc/api-gateway';
//constants
import {
  AllOpposTabQueryPreset,
  ManualOpposTabQueryPreset,
  ORGANIZATION_TABS,
  PastOpposTabQueryPreset,
  PendingOpposTabQueryPreset,
  RejectedOpposTabQueryPreset,
  StagedOpposTabQueryPreset,
  UpcomingOpposTabQueryPreset,
  VacanciesOpposTabQueryPreset,
} from 'core/constants';
import {TABS_TITLES} from './data';
import {OrganisationTabQueryValue} from './interfaces';

export const QueryPreset: Map<ORGANIZATION_TABS, OrganisationTabQueryValue> = new Map();

QueryPreset.set(ORGANIZATION_TABS.ALL, AllOpposTabQueryPreset);
QueryPreset.set(ORGANIZATION_TABS.STAGED, StagedOpposTabQueryPreset);
QueryPreset.set(ORGANIZATION_TABS.UPCOMING, UpcomingOpposTabQueryPreset);
QueryPreset.set(ORGANIZATION_TABS.PENDING, PendingOpposTabQueryPreset);
QueryPreset.set(ORGANIZATION_TABS.VACANCIES, VacanciesOpposTabQueryPreset);
QueryPreset.set(ORGANIZATION_TABS.MANUAL, ManualOpposTabQueryPreset);
QueryPreset.set(ORGANIZATION_TABS.PAST, PastOpposTabQueryPreset);
QueryPreset.set(ORGANIZATION_TABS.REJECTED, RejectedOpposTabQueryPreset);

export const getInitialTab = (search: string): ORGANIZATION_TABS => {
  const initialTab = getCurrentTabFromQuery(search);

  return !!initialTab ? initialTab : ORGANIZATION_TABS.UPCOMING;

  function getCurrentTabFromQuery(query: string): ORGANIZATION_TABS | null {
    const parsedQuery = qs.parse(query, {ignoreQueryPrefix: true});

    return !!parsedQuery.tab ? (parsedQuery.tab as ORGANIZATION_TABS) : null;
  }
};

export const getTabCounter = (oppoCounts: ICountsOpportunitiesResponse) => {
  return [
    {
      title: TABS_TITLES.upcoming,
      counter: oppoCounts.opportunitiesUpcoming,
      currentTab: ORGANIZATION_TABS.UPCOMING,
    },
    {
      title: TABS_TITLES.pending,
      counter: oppoCounts.opportunitiesPendingVolunteers,
      currentTab: ORGANIZATION_TABS.PENDING,
    },
    {
      title: TABS_TITLES.vacancies,
      counter: oppoCounts.opportunitiesVacancies,
      currentTab: ORGANIZATION_TABS.VACANCIES,
    },
    {
      title: TABS_TITLES.rejected,
      counter: oppoCounts.opportunitiesRejected,
      currentTab: ORGANIZATION_TABS.REJECTED,
    },
    {
      title: TABS_TITLES.past,
      counter: oppoCounts.opportunitiesPast,
      currentTab: ORGANIZATION_TABS.PAST,
    },
    {
      title: TABS_TITLES.staged,
      counter: oppoCounts.opportunitiesStaged,
      currentTab: ORGANIZATION_TABS.STAGED,
    },
    {
      title: TABS_TITLES.all,
      counter: oppoCounts.opportunitiesTotal,
      currentTab: ORGANIZATION_TABS.ALL,
    },
  ];
};
