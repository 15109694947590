import * as Yup from 'yup';
// types
import {IUpdateUserRequest, IUserResponse} from '@joc/api-gateway';
// validation
import {firstNameSchema, lastNameSchema, emailSchema, requiredPhoneNumberSchema} from 'core/validation';

export type UserInfoFormValues = IUpdateUserRequest;

export const getInitalValues = (userData: IUserResponse | null): UserInfoFormValues => ({
  firstName: userData?.firstName || '',
  lastName: userData?.lastName || '',
  email: userData?.email || '',
  phoneNumber: userData?.phoneNumber || '',
  imagePath: userData?.imagePath || '',
});

export const validationSchema = Yup.object().shape({
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  email: emailSchema,
  phoneNumber: requiredPhoneNumberSchema,
});
