import {FC, ReactNode} from 'react';
import cx from 'classnames';
//images
import {ReactComponent as ThanksSvg} from 'assets/image/onboarding/thanks-for-contact.svg';
import {ReactComponent as CongratsSvg} from 'assets/image/onboarding/chesed.svg';
import {ReactComponent as PensiveSvg} from 'assets/image/onboarding/pensive.svg';
import {ReactComponent as ContactSvg} from 'assets/image/onboarding/contact.svg';
import {ReactComponent as ThinkingSvg} from 'assets/image/onboarding/thinking.svg';
import LoginVSvg from 'assets/image/login/login-image.png';
import {ReactComponent as CongratsMyChesedSvg} from 'assets/image/onboarding/congrats-my-chesed.svg';
import {ReactComponent as EmptyMyChesedSvg} from 'assets/image/onboarding/empty-my-chesed.svg';

import styles from './Appeal.module.scss';

type AppealPropsType = {
  children?: ReactNode;
  styleCongrats?: boolean;
  styleJoin?: boolean;
  stylePensive?: boolean;
  styleEmptyChesed?: boolean;
  styleThink?: boolean;
  styleLoginV?: boolean;
  styleContact?: boolean;
  styleTable?: boolean;
  isHeightDisable?: boolean;
  isHeightVisibility?: boolean;
  isFullHeight?: boolean;
  styleRecoveryCongrats?: boolean;
};

const Appeal: FC<AppealPropsType> = ({
  children,
  styleCongrats,
  stylePensive,
  styleEmptyChesed,
  styleThink,
  styleJoin,
  styleLoginV,
  styleContact,
  styleTable,
  styleRecoveryCongrats,
  isHeightVisibility,
  isHeightDisable,
  isFullHeight,
}: AppealPropsType) => {
  return (
    <div
      className={cx(styles.container, {
        [styles.congrats]: styleCongrats,
        [styles.styleTable]: styleTable,
        [styles.paddingTopDisabled]: styleLoginV,
        [styles.heightDisable]: isHeightDisable,
        [styles.isHeightVisibility]: isHeightVisibility,
        [styles.fullHeight]: isFullHeight,
      })}
    >
      <div className={styles.container__gradient} />
      {styleCongrats || styleRecoveryCongrats ? (
        <CongratsSvg className={styles.container__image} />
      ) : stylePensive ? (
        <PensiveSvg className={styles.container__image} />
      ) : styleThink ? (
        <ThinkingSvg className={styles.container__image} />
      ) : styleJoin ? (
        <CongratsMyChesedSvg className={styles.container__image} />
      ) : styleLoginV ? (
        <img src={LoginVSvg} className={styles.container__image_login} alt="login" />
      ) : styleContact ? (
        <ContactSvg className={styles.container__image} />
      ) : styleEmptyChesed ? (
        <EmptyMyChesedSvg className={styles.container__image} />
      ) : (
        <ThanksSvg className={styles.container__image} />
      )}
      {children}
    </div>
  );
};

export default Appeal;
