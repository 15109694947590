import {FC, useEffect, useState} from 'react';
import {Formik} from 'formik';

// redux
import {useDispatch, useSelector} from 'react-redux';
import {Store} from '../../../../redux/root';
import {getGradesSettings, updateGrades, updateOrg} from '../../../../redux/organization-service/actions';
import {
  selectorGetGrades,
  selectorGetGradesIsLoading,
  selectorGetOrgGoals,
  selectorGetOrgInfo,
} from '../../../../redux/organization-service/selector';

// context
import {YearlyGoalsContextProvider} from './context';
import {useOrganizationSettingsContext} from '../context';

// components
import YearlyGoalsForm from './YearlyGoalsForm';

// types
import {IYearlyGoalRequest, YearlyGoalRequest} from '@joc/api-gateway/lib/api-client';

import styles from '../OrganizationSetting.module.scss';
import {changedGrades, YearlyGoalsValidationSchema} from './helpers';
import {useTranslation} from 'react-i18next';

const YearlyGoals: FC = () => {
  const dispatch = useDispatch();
  const schoolId = useSelector((store: Store) => store.organizationRedux.organizationInfo?.schoolId);
  const orgData = useSelector(selectorGetOrgInfo);
  const orgYearlyGoals = useSelector(selectorGetOrgGoals);
  const isLoadingGrades = useSelector(selectorGetGradesIsLoading);
  const grades = useSelector(selectorGetGrades);
  const [gradesSettings, setGradesSettings] = useState<IYearlyGoalRequest[]>([]);
  const {t} = useTranslation(['settings', 'messages']);

  const [initialValues, setInitialValues] = useState<{
    gradeId?: number;
    hourYearlyGoal: string;
    actYearlyGoal: string;
  }>();
  const {setIsUpdateSuccess} = useOrganizationSettingsContext();

  useEffect(() => {
    if (!isLoadingGrades && !grades.length && schoolId) {
      dispatch(getGradesSettings(+schoolId));
    } else if (!isLoadingGrades && grades.length && schoolId) {
      setGradesSettings(grades);

      const firstSchoolGrade = grades.find((grade) => +grade.gradeId === 1);

      setInitialValues({
        gradeId: 1,
        actYearlyGoal: String(firstSchoolGrade?.actYearlyGoal || 0),
        hourYearlyGoal: String(firstSchoolGrade?.hourYearlyGoal || 0),
      });
    } else if (!isLoadingGrades) {
      setInitialValues({
        actYearlyGoal: String(orgYearlyGoals?.actYearlyGoal || 0),
        hourYearlyGoal: String(orgYearlyGoals?.hourYearlyGoal || 0),
      });
    }
  }, [grades, schoolId, orgYearlyGoals]);

  const handleSubmit = (values: IYearlyGoalRequest | {hourYearlyGoal: string; actYearlyGoal: string}) => {
    if (orgData?.id) {
      if (orgData?.schoolId && Object.hasOwn(values, 'gradeId')) {
        const gradesToUpdate = changedGrades(grades, gradesSettings).map((grade) => YearlyGoalRequest.fromJS(grade));

        dispatch(updateGrades(+orgData.id, +orgData.schoolId, {yearlyGoals: gradesToUpdate}));
        setIsUpdateSuccess(true);
      } else {
        dispatch(
          updateOrg(+orgData?.id, {
            actYearlyGoal: +values?.actYearlyGoal || 0,
            hourYearlyGoal: +values?.hourYearlyGoal || 0,
          })
        );
        setIsUpdateSuccess(true);
      }
    }
  };

  return (
    <div className={styles.organisationSettings__tab}>
      <div className={styles.organisationSettings__tab__title}>{t('settings:yearlyGoals.subTitle')}</div>
      <div className={styles.organisationSettings__tab__form}>
        {initialValues && (
          <YearlyGoalsContextProvider value={{gradesSettings, setGradesSettings}}>
            <Formik
              initialValues={initialValues}
              validationSchema={YearlyGoalsValidationSchema}
              onSubmit={handleSubmit}
            >
              <YearlyGoalsForm schoolId={schoolId} />
            </Formik>
          </YearlyGoalsContextProvider>
        )}
      </div>
    </div>
  );
};

export default YearlyGoals;
