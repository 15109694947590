import {Dispatch, FC, SetStateAction, useMemo} from 'react';
import cx from 'classnames';
//formik
import {Field, Form, Formik} from 'formik';
//translation
import {useTranslation} from 'react-i18next';
//form configuration
import {RecoveryFormValues, validationSchema} from './formConfiguration';
//components
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import Input from 'shared/inputs/Input';
//api
import {API} from 'core/API';
import {ChangePasswordRequest} from '@joc/api-gateway/lib/api-client';
//styles
import styles from './RecoveryForm.module.scss';

type RecoveryFormPropsType = {
  resetToken: string;
  setIsRecoverySuccess: Dispatch<SetStateAction<boolean>>;
  setResponseError: Dispatch<SetStateAction<boolean>>;
  setErrorMessage: Dispatch<SetStateAction<string>>;
};

const RecoveryForm: FC<RecoveryFormPropsType> = ({
  resetToken,
  setIsRecoverySuccess,
  setResponseError,
  setErrorMessage,
}) => {
  const {t} = useTranslation(['messages', 'inputs', 'buttons']);
  const initialValues = {password: '', confirmPassword: ''};

  const submitClickHandler = async (values: RecoveryFormValues) => {
    try {
      await API.setNewPassword(ChangePasswordRequest.fromJS({token: resetToken, password: values.password}));
      setIsRecoverySuccess(true);
    } catch (error) {
      setErrorMessage(error?.response?.message || error.message);
      setResponseError(true);
    }
  };

  return (
    <div className={styles.container}>
      <span className={cx('title', styles.title)}>{t('messages:setNewPass')}</span>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submitClickHandler}
      >
        <Form className={styles.form}>
          <Field
            name="password"
            type="password"
            placeholder={t('inputs:placeholders.password')}
            isDisabledMargin
            component={Input}
          />
          <Field
            name="confirmPassword"
            type="password"
            placeholder={t('inputs:placeholders.confirmPassword')}
            isDisabledMargin
            component={Input}
          />
          <ButtonDefault submitType classList={['primary', 'extra_lg']} title={t('buttons:button.confirm')} />
        </Form>
      </Formik>
    </div>
  );
};

export default RecoveryForm;
