import {Dispatch, FC, SetStateAction} from 'react';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
import moment, {Moment} from 'moment';
import MomentUtils from '@date-io/moment';
import 'moment/locale/he';
//assets
import {ReactComponent as ArrowSvg} from 'assets/image/button-arrow.svg';
//types
import {IDateRange, IOpportunityResponse} from '@joc/api-gateway';
//components
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date';
import {createTheme, ThemeProvider} from '@material-ui/core';
import {Overrides} from '@material-ui/core/styles/overrides';
//styles
import s from './index.module.scss';

type StaticDatePickerPropsType = {
  setSelectedDate: Dispatch<SetStateAction<Date | Moment>>;
  selectedDate: Date;
  opportunitiesCurrentMonth: Array<IOpportunityResponse>;
  monthChangeHandler?: Dispatch<SetStateAction<IDateRange>>;
  isDisabledPastDate?: boolean;
};

const calendarTheme = createTheme({
  overrides: {
    MuiTypography: {
      body1: {
        fontFamily: 'Rubik, sans-serif',
        fontSize: '18px',
        lineHeight: '20px',
        color: '#1C2F4C',
        letterSpacing: '-0.3px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      },
      body2: {
        fontFamily: 'Rubik, sans-serif',
        color: '#1C2F4C',
        fontSize: '14px',
        lineHeight: '19px',
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        width: '328px',
        height: '316px',
      },
    },
    MuiPickersCalendarHeader: {
      transitionContainer: {
        height: '20px',
      },
      dayLabel: {
        fontFamily: 'Rubik, sans-serif',
        color: '#1C2F4C',
        fontSize: '12px',
        lineHeight: '16px',
      },
      switchHeader: {
        margin: '0 auto',
        justifyContent: 'center',
        maxWidth: '235px',
      },
      iconButton: {
        width: '18px',
        height: '18px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '6px',
        borderRadius: '50%',

        '&:hover': {
          backgroundColor: 'rgba(198, 204, 254, 0.5)',
        },
      },
    },
    MuiPickersDay: {
      current: {
        color: '#1C2F4C',
      },
      day: {
        width: '32px',
        height: '32px',
        fontFamily: 'Rubik',
        color: '#1C2F4C',
        fontSize: '14px',
        lineHeight: '18px',
        margin: '0',
        letterSpacing: '0.22px',
      },
      daySelected: {
        color: '#fff',
        backgroundColor: '#214ba5',

        '&:hover': {
          backgroundColor: '#0017D0',
        },
      },
      hidden: {
        opacity: '1',
        color: '#C6CCFE !important',
        backgroundColor: 'inherit !important',
      },
      dayDisabled: {
        opacity: '1',
        color: '#C6CCFE',
      },
    },
    MuiPickersCalendar: {
      week: {
        marginBottom: '6px',
        justifyContent: 'space-between',
        padding: '0 28px',
      },
    },
    MuiIconButton: {
      label: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '100%',
      },
      root: {
        '&:hover': {
          backgroundColor: '#F1F3FF;',
        },
      },
    },
    MuiTouchRipple: {
      root: {
        width: '100%',
        height: '100%',
      },
      ripple: {
        backgroundColor: '#214ba5',
      },
    },
  } as Overrides,
});

const StaticDatePicker: FC<StaticDatePickerPropsType> = ({
  selectedDate,
  setSelectedDate,
  opportunitiesCurrentMonth,
  monthChangeHandler,
  isDisabledPastDate,
}: StaticDatePickerPropsType) => {
  const {i18n} = useTranslation();

  const getMarkedDays = (
    day: MaterialUiPickersDate,
    selectedDate: MaterialUiPickersDate,
    isInCurrentMonth: boolean,
    dayComponent: JSX.Element
  ): JSX.Element => {
    const isMarked =
      day &&
      opportunitiesCurrentMonth
        .filter(
          (i) =>
            moment(i.endDate).format('DD/MM/YY') !== moment(selectedDate).format('DD/MM/YY') &&
            moment(new Date()).diff(moment(i.endDate), 'days') < 1
        )
        .map((i) => moment(i.endDate).format('DD/MM/YY'))
        .includes(moment(day).format('DD/MM/YY'));
    if (isMarked && isInCurrentMonth) return <div className={s.marked_day}>{dayComponent}</div>;
    return dayComponent;
  };

  const onMonthChange = (date: MaterialUiPickersDate | Date) => {
    if (monthChangeHandler && date) {
      const fromDate = new Date(date);
      const year = fromDate.getFullYear();
      const nextMonth = fromDate.getMonth() + 1;

      const toDate = new Date(year, nextMonth, 1);
      monthChangeHandler({fromDate, toDate});
    }
  };
  const locale = i18n.language;

  return (
    <div className={s.datepicker}>
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={locale}>
        <ThemeProvider theme={calendarTheme}>
          <DatePicker
            autoOk
            animateYearScrolling
            disableToolbar
            orientation="portrait"
            variant="static"
            openTo="date"
            value={selectedDate || null}
            onChange={(date) => setSelectedDate(date as unknown as Moment)}
            onMonthChange={(date) => onMonthChange(date)}
            disablePast={isDisabledPastDate || false}
            rightArrowIcon={<ArrowSvg className={cx(s.button_arrow, s.arrow_right)} />}
            leftArrowIcon={<ArrowSvg className={cx(s.button_arrow, s.arrow_left)} />}
            renderDay={(
              day: MaterialUiPickersDate,
              selectedDate: MaterialUiPickersDate,
              isInCurrentMonth: boolean,
              dayComponent: JSX.Element
            ): JSX.Element => getMarkedDays(day, selectedDate, isInCurrentMonth, dayComponent)}
          />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default StaticDatePicker;
