import {useState, Dispatch, SetStateAction} from 'react';

export interface PopupState {
  popupState: boolean;
  popupSetState: Dispatch<SetStateAction<boolean>>;
}

export interface PopupsState {
  edit: PopupState;
  editStaged: PopupState;
  share: PopupState;
  delete: PopupState;
  update: PopupState;
  manual: PopupState;
  snackbar: PopupState;
  confirmation: PopupState;
}

interface IPopupsActions {
  handleEditManualOpp: () => void;
  handleOpenSharePopup: () => void;
  handleOpenDeleteModal: () => void;
  handleOpenUpdatePopup: () => void;
  handleOpenManualPopup: () => void;
  handleEditStagedOpp: () => void;
  handleOpenDeleteTypeModal: () => void;
  handleCloseDeleteTypeModal: () => void;
}

interface IIUseTableActionsPopupResponse {
  popupsState: PopupsState;
  popupsActions: IPopupsActions;
}

interface IUseTableActionsPopupProps {
  (handleCloseMainPopup: () => void): IIUseTableActionsPopupResponse;
}

export const useTableActionsPopup: IUseTableActionsPopupProps = (handleCloseMainPopup) => {
  const [isSnackbar, setIsSnackbar] = useState<boolean>(false);
  const [isSharePopup, setIsSharePopup] = useState<boolean>(false);
  const [isShowUpdatePopup, setIsShowUpdatePopup] = useState<boolean>(false);
  const [isShowManualPopup, setIsShowManualPopup] = useState<boolean>(false);
  const [isShowConfirmation, setIsShowConfirmation] = useState<boolean>(false);
  const [isOpenEditManualOppo, setIsOpenEditManualOppo] = useState<boolean>(false);
  const [isOpenEditStagedOppo, setIsOpenEditStagedOppo] = useState<boolean>(false);

  const [isDeleteTypeModalOpen, setIsDeleteTypeModalOpen] = useState<boolean>(false);

  const popupsState: PopupsState = {
    snackbar: {popupState: isSnackbar, popupSetState: setIsSnackbar},
    share: {popupState: isSharePopup, popupSetState: setIsSharePopup},
    update: {popupState: isShowUpdatePopup, popupSetState: setIsShowUpdatePopup},
    manual: {popupState: isShowManualPopup, popupSetState: setIsShowManualPopup},
    edit: {popupState: isOpenEditManualOppo, popupSetState: setIsOpenEditManualOppo},
    editStaged: {popupState: isOpenEditStagedOppo, popupSetState: setIsOpenEditStagedOppo},
    delete: {popupState: isDeleteTypeModalOpen, popupSetState: setIsDeleteTypeModalOpen},
    confirmation: {popupState: isShowConfirmation, popupSetState: setIsShowConfirmation},
  };

  const handleOpenDeleteTypeModal = () => {
    popupsState.confirmation.popupSetState(false);
    popupsState.delete.popupSetState(true);
  };

  const handleCloseDeleteTypeModal = () => popupsState.delete.popupSetState(false);

  const handleOpenUpdatePopup = () => {
    popupsState.update.popupSetState(true);
    handleCloseMainPopup();
  };

  const handleOpenManualPopup = () => {
    popupsState.manual.popupSetState(true);
    handleCloseMainPopup();
  };

  const handleEditManualOpp = () => {
    popupsState.edit.popupSetState(true);
    handleCloseMainPopup();
  };

  const handleEditStagedOpp = () => {
    popupsState.editStaged.popupSetState(true);
    handleCloseMainPopup();
  };

  const handleOpenSharePopup = () => {
    popupsState.share.popupSetState(true);
    handleCloseMainPopup();
  };

  const handleOpenDeleteModal = () => {
    handleCloseMainPopup();
    popupsState.confirmation.popupSetState(true);
  };

  const popupsActions = {
    handleOpenDeleteTypeModal,
    handleCloseDeleteTypeModal,
    handleOpenUpdatePopup,
    handleOpenManualPopup,
    handleEditManualOpp,
    handleEditStagedOpp,
    handleOpenSharePopup,
    handleOpenDeleteModal,
  };

  return {popupsState, popupsActions};
};
