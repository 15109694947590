import {Dispatch, FC, SetStateAction, useEffect, useMemo, useState} from 'react';
import PopupContainer from 'shared/components/PopupContainer';
import Appeal from 'shared/components/Appeal/DefaultRenderer';
import styles from '../../../Organization/EditMannualOppoPopup/index.module.scss';
import {useTranslation} from 'react-i18next';
import {Formik} from 'formik';
import ManualOpportunityMainForm from '../../../../pages/Volunteers/CreateManualOppoV/ManualOpportunityMainForm';
import {dynamicManualOppoValidation} from '../../../../core/validation';
import {ManualOpportunityInitialValuesType} from '../../../../core/types';
import {useSelector} from 'react-redux';
import {Store} from '../../../../redux/root';
import moment from 'moment/moment';
import {OPPORTUNITIES_INITIAL_VALUES_MANUAL} from '../../../../core/constants';
import {selectorGetOpportunityById} from '../../../../redux/opportunities-service/selector';
import {
  createManualOpportunity,
  getOpportunityById,
  resetCurrentOpportunity,
} from '../../../../redux/opportunities-service/action';
import useThunkDispatch from '../../../../hooks/useThunkDispatch';
import {convertFromStringToItem} from '../../../Organization/CreateManualOppoPopup/helper';
import {ICreateManualOpportunityRequest} from '@joc/api-gateway/lib/api-client';
import {setError} from '../../../../redux/error-service/action';

type ManualStagedOpportunityProps = {
  setIsShowPopup: Dispatch<SetStateAction<boolean>>;
  stagedOpportunityId: number;
};

const ManualStagedOpportunity: FC<ManualStagedOpportunityProps> = ({setIsShowPopup, stagedOpportunityId}) => {
  const {t} = useTranslation(['errors', 'buttons', 'messages', 'form']);
  const thunkDispatch = useThunkDispatch();
  const [isLoading, setIsLoading] = useState(true);

  const userId = useSelector((store: Store) =>
    store.userRedux.userData?.id ? +store.userRedux.userData?.id : undefined
  );
  const volunteerId = useSelector((store: Store) => store.userRedux.userData?.volunteerId);
  const stagedOpportunity = useSelector(selectorGetOpportunityById);
  const [isVisibleHashtagField, setIsVisibleHashtagField] = useState<boolean>(false);

  const dataPreset = {
    ...OPPORTUNITIES_INITIAL_VALUES_MANUAL,
    startDate: new Date(),
    startTime: moment().add(1, 'hours').format('HH:mm'),
  };

  const initValues = useMemo(
    () =>
      stagedOpportunity
        ? ({
            ...dataPreset,
            opportunityName: stagedOpportunity.opportunityName,
            opportunityDescription: stagedOpportunity.opportunityDescription,
            organisationId: stagedOpportunity.organisation!.id,
            organisation: stagedOpportunity.organisation,
            isApprovalRequired: stagedOpportunity.isApprovalRequired,
            user: userId ? +userId : 0,
            volunteerId: volunteerId ? +volunteerId : 0,
            recipientName: stagedOpportunity.recipientName,
            recipientEmail: stagedOpportunity.recipientEmail,
            recipientPhoneNumber: stagedOpportunity.recipientPhoneNumber,
            tagId: +stagedOpportunity.tagId,
            tag: stagedOpportunity.tag,
            endDate: stagedOpportunity.endDate,
          } as unknown as ManualOpportunityInitialValuesType)
        : dataPreset,
    [stagedOpportunity]
  );

  const validationSchema = useMemo(() => dynamicManualOppoValidation(), []);

  useEffect(() => {
    setIsLoading(true);
    const fetchOpportunity = async () => await thunkDispatch(getOpportunityById(+stagedOpportunityId));

    fetchOpportunity().finally(() => setIsLoading(false));

    return () => {
      thunkDispatch(resetCurrentOpportunity());
    };
  }, [stagedOpportunityId]);

  useEffect(() => {
    if (stagedOpportunity) {
      setIsVisibleHashtagField(!!stagedOpportunity?.tagId);
    }
  }, [stagedOpportunity]);

  const submitClickHandler = async (values: ManualOpportunityInitialValuesType) => {
    try {
      setIsLoading(true);

      const body = document.createElement('body');
      body.innerHTML = values.opportunityDescription;

      await thunkDispatch(
        createManualOpportunity({
          ...values,
          organisationId:
            stagedOpportunity?.organisation?.id === 'null' ? undefined : stagedOpportunity?.organisation?.id,
          opportunityDescription: body.outerHTML,
        } as unknown as ICreateManualOpportunityRequest)
      );
    } catch (err) {
      thunkDispatch(setError(err?.response?.message || err.message, err?.response?.code || err.code));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PopupContainer isDisablePadding setIsShowPopup={setIsShowPopup}>
      <Appeal styleContact>
        <div className={styles.title}>{t('form:createManualOpp.log')}</div>
        <div className={styles.form__container}>
          <Formik
            enableReinitialize
            initialValues={initValues}
            validationSchema={validationSchema}
            onSubmit={submitClickHandler}
          >
            <ManualOpportunityMainForm
              isLoading={isLoading}
              isVisibleHashtagField={isVisibleHashtagField}
              setIsVisibleHashtagField={setIsVisibleHashtagField}
              isStaged
            />
          </Formik>
        </div>
      </Appeal>
    </PopupContainer>
  );
};

export default ManualStagedOpportunity;
