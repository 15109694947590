import {FC, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Bar, BarChart, CartesianGrid, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
//types
import {IVolunteersSpendTimeResponse} from '@joc/api-gateway';
//components
import {renderColorfulLegendText} from '../LegendRenderer';
import Loader from 'shared/components/Loader';
import {getTransformChartData} from './helper';

type GradeTimeSpentBarChartProps = {
  usersGradeTimeSpent: Array<IVolunteersSpendTimeResponse>;
  isLoading: boolean;
};

export const GradeTimeSpentBarChart: FC<GradeTimeSpentBarChartProps> = ({
  usersGradeTimeSpent,
  isLoading,
}: GradeTimeSpentBarChartProps) => {
  const {t} = useTranslation(['statistics', 'common']);

  const transformedChartData = useMemo(() => getTransformChartData(usersGradeTimeSpent), [usersGradeTimeSpent]);

  return isLoading ? (
    <Loader />
  ) : (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        data={transformedChartData}
        margin={{
          top: 10,
          right: 30,
          left: 20,
          bottom: 10,
        }}
      >
        <CartesianGrid strokeDasharray="3 5" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip cursor={{fill: '#f1f3ff'}} />
        <Legend formatter={renderColorfulLegendText} />
        <Line type="monotone" dataKey="count" stroke="#214ba5" activeDot={{r: 8}} />
        <Bar dataKey={t('common:titles.totalHours')} stackId="name" fill="#214ba5" />;
      </BarChart>
    </ResponsiveContainer>
  );
};
