import {FC} from 'react';
import {connect, ConnectedProps} from 'react-redux';
//redux
import {Store} from 'redux/root';
import {setPushMessage} from 'redux/push-service/action';
import {selectorGetPushMessage} from 'redux/push-service/selector';
//components
import {Snackbar} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {List, ListItem, Typography} from '@mui/material';

const SnackbarInfo: FC<SnackbarInfoProps> = ({notification, setPushMessage}: SnackbarInfoProps) => {
  if (!notification.title) return null;

  const renderData = () => {
    if (!notification.data) return null;

    if (notification.data.type === 'registerVolunteers') {
      return (
        <List>
          <ListItem style={{margin: 0, padding: 0}}>
            <Typography style={{margin: 0, padding: 0, color: '#1c2f4c', fontSize: '13px', fontWeight: 500}}>
              <strong>Created students:</strong> {notification.data.createdVolunteersCount}
            </Typography>
          </ListItem>
          <ListItem style={{margin: 0, padding: 0}}>
            <Typography style={{margin: 0, padding: 0, color: '#1c2f4c', fontSize: '13px', fontWeight: 500}}>
              <strong>Updated students:</strong> {notification.data.updatedVolunteersCount}
            </Typography>
          </ListItem>
          <ListItem style={{margin: 0, padding: 0}}>
            <Typography style={{margin: 0, padding: 0, color: '#1c2f4c', fontSize: '13px', fontWeight: 500}}>
              <strong>New connected students:</strong> {notification.data.connectedVolunteersCount}
            </Typography>
          </ListItem>
        </List>
      );
    }
  };

  return (
    <Snackbar
      open={!!notification.title}
      autoHideDuration={notification.data ? null : 10000}
      onClose={() => setPushMessage({title: '', body: ''})}
    >
      <Alert onClose={() => setPushMessage({title: '', body: ''})} severity="info">
        {notification.title}
        <br />
        {notification.body}
        {renderData()}
      </Alert>
    </Snackbar>
  );
};

const mapStateToProps = (store: Store) => ({
  notification: selectorGetPushMessage(store),
});

const mapDispatchToProps = {
  setPushMessage,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type SnackbarInfoProps = ConnectedProps<typeof connector>;

export default connector(SnackbarInfo);
