import {ChangeEvent, FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';

//assets
import Camera from 'assets/image/Camera.svg';
import ButtonSvg from 'assets/image/trash-light.svg';

//types
import {FieldBaseProps} from 'core/types/field';

//components
import ItemPreview, {Item} from './ItemPreview';
import FieldWrapper from 'shared/inputs/FieldWrapper';
import InputErrorHint from 'shared/components/InputErrorHint';
import i18n from 'components/translate';

//functions
import {validateFileType} from 'core/functions';

// constants
import {defaultFileMimeTypes} from 'core/constants';

//styles
import styles from './UploadSeveralPhotos.module.scss';

type ItemGalleryProps = FieldBaseProps<
  {
    big?: boolean;
    title?: string;
    placeholder?: string;
    isRequired?: boolean;
    hideCustomRequiredError?: boolean;
  },
  Array<Item>
>;

const ItemGallery: FC<ItemGalleryProps> = ({field, form, disabled, ...props}) => {
  const [items, setItems] = useState<Array<Item>>([]);
  const [customErrors, setCustomError] = useState<Array<string>>([]);

  const validationT = i18n.getFixedT(null, 'validation');

  useEffect(() => {
    setItems(field.value);
  }, [field.value]);

  useEffect(() => {
    if (
      !props.hideCustomRequiredError &&
      props?.isRequired &&
      (form.touched.filePathes || !form.dirty) &&
      !form.values.filePathes.length
    ) {
      setCustomError(validationT('filePathesSchema.required'));
    }
  }, [form]);

  const {t} = useTranslation('inputs');

  const handleItemDelete = () => {
    const updatedItems = field.value.filter((item) => !item.isChecked);

    form.setFieldValue(field.name, [...updatedItems]);
    form.setFieldTouched(field.name, true);
  };

  const handleItemCheck = (id: Item['id']) => {
    const updated = field.value.map((item) => (item.id === id ? {...item, isChecked: !item.isChecked} : item));
    form.setFieldValue(field.name, updated);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files;
    if (!selectedFiles) return;

    const errors: string[] = [];
    const newItems: Item[] = [];

    form.setFieldTouched(field.name, true);

    Array.from(selectedFiles).forEach((file) => {
      const isValidType = validateFileType(file, defaultFileMimeTypes);

      if (!isValidType) {
        const alreadyLogged = errors.find((err) => err.includes(file.type));
        if (!alreadyLogged) {
          errors.push(`${validationT('upload.unsuported')} ${file.type}`, validationT('upload.validFormat'));
        }
        return;
      }

      newItems.push({
        id: URL.createObjectURL(file),
        src: URL.createObjectURL(file),
        isChecked: false,
        isNew: true,
        file,
      });
    });

    const files = [...items, ...newItems];
    const validatedFiles = files.filter(
      (item) => (item.file && validateFileType(item.file, defaultFileMimeTypes)) || !item.isNew
    );

    if (validatedFiles.length > 3) {
      errors.push(validationT('upload.maxFiles'));
    }

    setCustomError(errors);
    form.setFieldValue(field.name, validatedFiles.slice(0, 3));
  };

  const setFieldTouched = () =>
    props?.isRequired && form.values?.organisationId && form.setFieldTouched(field.name, true);

  return (
    <FieldWrapper field={field} form={form} disabled={disabled} {...props}>
      <div className={cx(styles.container, props.big && styles.big)} title={props.title}>
        <div className={cx(styles.input__wrapper, props.big && styles.big)}>
          <input
            id="imageUpload"
            className={styles.input}
            type="file"
            value=""
            accept="image/*, video/*"
            multiple
            onChange={handleInputChange}
            disabled={disabled}
          />
          <div className={styles.top}>
            <button className={styles.trash_button} type="button">
              <img src={ButtonSvg} alt="trash" width="15px" onClick={handleItemDelete} className={styles.trash_img} />
            </button>
          </div>
          <div className={styles.upload__wrapper}>
            <div className={styles.preview__view}>
              <div className={styles.checkbox}>
                {items.map((item) => (
                  // eslint-disable-next-line react/jsx-no-bind
                  <ItemPreview key={item.src} item={item} handleItemCheck={handleItemCheck} />
                ))}
              </div>
            </div>
          </div>
          <div className={styles.upload__options}>
            <label className={styles.option__item} htmlFor="imageUpload" onClick={setFieldTouched}>
              <img src={Camera} alt="camera" />
            </label>
            <label className={styles.option__item} htmlFor="imageUpload" onClick={setFieldTouched}>
              {t('placeholders.addPhotos')} ({items.length}/3)
            </label>
          </div>
        </div>
      </div>
      {customErrors && <InputErrorHint errorText={customErrors} />}
    </FieldWrapper>
  );
};

export default ItemGallery;
