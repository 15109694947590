import {FC, useEffect, useMemo, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useTranslation} from 'react-i18next';
//API
import {API} from 'core/API';
//redux
import {
  selectorGetUserVolunteerDiploma,
  selectorGetUserVolunteerPoints,
  selectorGetUserSpendTime,
  selectorGetUserData,
} from 'redux/user-service/selector';
//assets
import {ReactComponent as RookieSvg} from 'assets/image/rookie.svg';
import {ReactComponent as InfoSvg} from 'assets/image/info.svg';
//functions
import {setDiplomaSvg} from 'core/functions/diplomaSelectAnimation';
import {resetError, setError} from 'redux/error-service/action';
import {selectorGetError} from 'redux/error-service/selector';
//helpers
import {getStatusName, nextLevel, points, pointsLeft} from './helpers';
//types
import {Store} from 'redux/root';
import {IDiplomasResponse} from '@joc/api-gateway';
//components
import WhiteContainer from 'shared/components/WhiteContainer';
import Loader from 'shared/components/Loader';
import ResponseFailure from 'shared/components/ResponseFailure';
// styles
import styles from './Diploma.module.scss';
import {convertTimeFromSecondsToHours} from 'core/functions';

const DiplomaPage: FC<DiplomaPageProps> = ({
  userDiploma,
  userSpendTime,
  error,
  setError,
  resetError,
  userInfo,
}: DiplomaPageProps) => {
  const {t} = useTranslation('diploma');

  const [isLoading, setIsLoading] = useState(true);
  const [allDiplomas, setAllDiplomas] = useState<Array<IDiplomasResponse>>();

  const getAllDiploma = async (unmounted?: boolean) => {
    try {
      // const response = await API.getAllDiplomas();
      if (!unmounted) {
        // setAllDiplomas(response);
        setIsLoading(false);
      }
    } catch (error) {
      setError(error?.response?.message || error.message, error?.response?.code || error.code);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let unmounted = false;
    getAllDiploma(unmounted);
    return () => {
      unmounted = true;
    };
  }, []);

  /**
   * TODO: A new sprint is planned for the redesign of the page
   */
  const isVisibleCurrentYearTime =
    userInfo && Object.keys(userInfo).includes('yearSpendTime') && userInfo?.yearSpendTime !== undefined;

  const currentYearTime = useMemo(
    () =>
      isVisibleCurrentYearTime
        ? `${convertTimeFromSecondsToHours(userInfo?.yearSpendTime || 0)} ${t('currentYear')} ${t('hoursSpent')}`
        : null,
    [userSpendTime]
  );

  const totalTime = useMemo(
    () => `${convertTimeFromSecondsToHours(userInfo?.spendTime || 0)} ${t('total')} ${t('hoursSpent')}`,
    [userSpendTime]
  );

  const userPoints = useMemo(
    () =>
      userInfo?.yearSpendTime
        ? Math.round(userInfo.yearSpendTime / 60)
        : userInfo?.spendTime
        ? Math.round(userInfo?.spendTime / 60)
        : 0,
    [userInfo]
  );

  const volunteerPointsLeft = useMemo(() => pointsLeft(nextLevel(allDiplomas, userPoints)), [allDiplomas, userPoints]);
  const volunteerDiplomaStatus = useMemo(
    () => pointsLeft(nextLevel(allDiplomas, userPoints, true), true),
    [allDiplomas, userPoints]
  );

  return (
    <WhiteContainer parentClassName={styles.wrap} title={t('title')}>
      {isLoading ? (
        <Loader loadProps={{stylePageCenter: true}} />
      ) : (
        <>
          <InfoSvg className={styles.info} />
          {error.state ? (
            <ResponseFailure
              message={error.message}
              buttonClickHandler={() => {
                resetError();
                getAllDiploma();
              }}
            />
          ) : (
            <div className={styles.wrap__main}>
              <div className={styles.pointBlock}>
                <div className={styles.volunteerPoint__block}>
                  <RookieSvg className={styles.volunteerPoint__ico} />
                  <div className={styles.volunteerPoint__description}>
                    <div className={styles.volunteerPoint__status}>{volunteerDiplomaStatus}</div>
                    <div className={styles.volunteerPoint__points}>
                      <div>{points(userPoints)}</div>
                      <div>{currentYearTime}</div>
                      <div>{totalTime}</div>
                    </div>
                    {!!nextLevel && <div className={styles.volunteerPoint__pointsLeft}>{volunteerPointsLeft}</div>}
                  </div>
                </div>
              </div>
              <div className={styles.pointMap}>
                {allDiplomas && (
                  // eslint-disable-next-line jsx-a11y/alt-text
                  <object
                    type="image/svg+xml"
                    data={setDiplomaSvg(allDiplomas, userDiploma?.statusName || allDiplomas[0].statusName)}
                  />
                )}
              </div>
            </div>
          )}
        </>
      )}
    </WhiteContainer>
  );
};

const mapStateToProps = (store: Store) => ({
  userDiploma: selectorGetUserVolunteerDiploma(store),
  userPoints: selectorGetUserVolunteerPoints(store),
  userSpendTime: selectorGetUserSpendTime(store),
  error: selectorGetError(store),
  userInfo: selectorGetUserData(store),
});

const mapDispatchToProps = {
  setError,
  resetError,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type DiplomaPageProps = ConnectedProps<typeof connector>;

export default connector(DiplomaPage);
