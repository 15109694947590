import {Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Formik} from 'formik';

//redux
import {resetError, setError} from 'redux/error-service/action';
import {selectorGetError} from 'redux/error-service/selector';

//types
import {
  IOpportunityResponse,
  CreateStagedOpportunityRequest,
  ICreateStagedOpportunityRequest,
  UpdateOpportunityRequest,
  IUpdateOpportunityRequest,
} from '@joc/api-gateway';

//hooks
import useThunkDispatch from 'hooks/useThunkDispatch';

//validation
import {stagedOpportunityValidationSchema} from './helpers';

//components
import ResponseFailure from 'shared/components/ResponseFailure';
import PopupMain from '../PopupMain';
import StagedOpportunityForm from '../../StagedOpportunityForm';

import styles from './PopupStaged.module.scss';
import {createStagedOpportunity, getOpportunitiesCount, updateOpportunity} from 'redux/opportunities-service/action';
import {selectorGetUserData} from 'redux/user-service/selector';
import {getAllHashtags} from 'redux/hashtag-service/actions';

type PopupStagedProps = {
  setIsShowPopup: Dispatch<SetStateAction<boolean>>;
  stagedOpportunity?: IOpportunityResponse;
  organisationId?: string;
  isStagedTab?: boolean;
};

const PopupStaged: FC<PopupStagedProps> = ({
  setIsShowPopup,
  stagedOpportunity,
  organisationId,
  isStagedTab = false,
}) => {
  const dispatch = useDispatch();
  const thunkDispatch = useThunkDispatch();
  const {t} = useTranslation(['popup', 'errors']);
  const user = useSelector(selectorGetUserData);

  const error = useSelector(selectorGetError);

  const [isLoading, setIsLoading] = useState(false);

  const [isVisibleHashtagField, setIsVisibleHashtagField] = useState<boolean>(false);

  useEffect(() => {
    const fetchHashtags = async () => {
      if (organisationId) await thunkDispatch(getAllHashtags(organisationId));
    };
    fetchHashtags().finally();
  }, [organisationId]);

  useEffect(() => {
    if (stagedOpportunity) setIsVisibleHashtagField(!!stagedOpportunity.tagId);
  }, [stagedOpportunity]);

  const initialValues = useMemo<ICreateStagedOpportunityRequest>(
    () =>
      stagedOpportunity
        ? CreateStagedOpportunityRequest.fromJS(stagedOpportunity)
        : CreateStagedOpportunityRequest.fromJS({}),
    [stagedOpportunity]
  );

  const submitClickHandler = useCallback(
    async (values: ICreateStagedOpportunityRequest) => {
      const body = document.createElement('body');
      body.innerHTML = values.opportunityDescription || '';
      values.organisationId = localStorage.getItem('organisationId') || '';
      values.coordinatorId = Number(user!.id);

      setIsLoading(true);
      try {
        if (stagedOpportunity) {
          await thunkDispatch(
            updateOpportunity(
              stagedOpportunity.id,
              UpdateOpportunityRequest.fromJS({
                ...stagedOpportunity,
                ...values,
                user: Number(user!.id),
                tagId: values.tagId ? Number(values.tagId) : null,
              })
            )
          );
        } else {
          await thunkDispatch(createStagedOpportunity(values, isStagedTab));
        }
        await thunkDispatch(getOpportunitiesCount(values.organisationId));
        setIsShowPopup(false);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        thunkDispatch(setError(error?.response?.message || error.message, error?.response?.code || error.code));
      }
    },
    [thunkDispatch, user, isStagedTab]
  );

  return (
    <PopupMain setIsShowPopup={setIsShowPopup}>
      <>
        {error.state ? (
          <ResponseFailure
            styleTable
            message={error.message ? error.message : t('errors:errorPlease')}
            buttonClickHandler={() => dispatch(resetError())}
          />
        ) : (
          <div className={styles.popupStaged}>
            <h2 className="title">
              {stagedOpportunity
                ? t('popup:opportunity.updateStagedOpportunity')
                : t('popup:opportunity.createANewStagedOpportunity')}
            </h2>
            <Formik
              initialValues={initialValues}
              validationSchema={stagedOpportunityValidationSchema}
              onSubmit={submitClickHandler}
            >
              <StagedOpportunityForm
                isLoading={isLoading}
                isVisibleHashtagField={isVisibleHashtagField}
                setIsVisibleHashtagField={setIsVisibleHashtagField}
              />
            </Formik>
          </div>
        )}
      </>
    </PopupMain>
  );
};

export default PopupStaged;
