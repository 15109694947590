import {createTheme} from '@material-ui/core';
import {Overrides} from '@material-ui/core/styles/overrides';

export const muiDateTinePickerTheme = createTheme({
  overrides: {
    MuiFormHelperText: {
      root: {
        '&.Mui-error': {
          position: 'absolute',
          top: '100%',
        },
      },
    },
    MuiTextField: {
      root: {
        width: '100%',
      },
    },
    MuiInputBase: {
      root: {display: 'block'},
    },
    MuiInput: {
      root: {
        position: 'relative',
        width: '100%',
      },
      input: {
        fontSize: '18px',
        lineHeight: '20px',
        width: '100%',
        border: '1px solid #C6CCFE',
        height: '40px',
        outline: 'none',
        userSelect: 'none',
        paddingLeft: '20px',
        borderRadius: '30px',
        paddingRight: '15px',
        justifyContent: 'center',
        backgroundColor: '#fff',
        boxSizing: 'border-box',
        display: 'block',
        transition: 'all 0.2s ease',
        fontFamily: 'Rubik',
        '&:focus': {
          borderColor: '#ffa133',
        },
      },

      underline: {
        '&::before, &::after': {
          display: 'none',
        },
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        width: '328px',
        height: '316px',
      },
    },
    MuiPickersCalendarHeader: {
      transitionContainer: {
        height: '20px',
      },
      dayLabel: {
        fontFamily: 'Rubik, sans-serif',
        color: '#1C2F4C',
        fontSize: '12px',
        lineHeight: '16px',
      },
      switchHeader: {
        margin: '0 auto',
        justifyContent: 'center',
        maxWidth: '235px',
      },
      iconButton: {
        width: '18px',
        height: '18px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '6px',
        borderRadius: '50%',

        '&:hover': {
          backgroundColor: 'rgba(198, 204, 254, 0.5)',
        },
      },
    },
    MuiPickersDay: {
      current: {
        color: '#1C2F4C',
      },
      day: {
        width: '32px',
        height: '32px',
        fontFamily: 'Rubik',
        color: '#1C2F4C',
        fontSize: '14px',
        lineHeight: '18px',
        margin: '0',
        letterSpacing: '0.22px',
      },
      daySelected: {
        color: '#fff',
        backgroundColor: '#214ba5',

        '&:hover': {
          backgroundColor: '#0017D0',
        },
      },
      hidden: {
        opacity: '1',
        color: '#C6CCFE !important',
        backgroundColor: 'inherit !important',
      },
      dayDisabled: {
        opacity: '1',
        color: '#C6CCFE',
      },
    },
    MuiPickersCalendar: {
      week: {
        marginBottom: '6px',
        justifyContent: 'space-between',
        padding: '0 28px',
      },
    },
    MuiIconButton: {
      label: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '100%',
      },
      root: {
        '&:hover': {
          backgroundColor: '#F1F3FF;',
        },
      },
    },
    MuiTouchRipple: {
      root: {
        width: '100%',
        height: '100%',
      },
      ripple: {
        backgroundColor: '#214ba5!important',
      },
      child: {
        backgroundColor: '#214ba5!important',
        opacity: '0.3!important',
      },
    },
    MuiInputAdornment: {
      root: {
        position: 'absolute',
        right: '5px',
        top: '50%',
        height: 'auto',
        transform: 'translateY(-50%)',
        '& > .MuiIconButton-root': {
          padding: '5px',
        },
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        height: 'auto',
        backgroundColor: '#214ba5',
      },
      hourMinuteLabel: {
        color: 'red',
      },
    },

    MuiPickersToolbarText: {
      toolbarTxt: {
        fontSize: '20px',
      },
    },
  } as Overrides,
});
