import * as Yup from 'yup';
// types
import {GetLocationIdByAddressRequest} from '@joc/api-gateway';
// validation
import {emailSchema, orgAddressMandatorySchema, requiredPhoneNumberSchema, websiteSchema} from 'core/validation';

export type ContactOrgSignupFormValues = {
  organizationPhoneNumber: string;
  organizationEmail: string;
  organizationWebSite: string;
  address: GetLocationIdByAddressRequest;
};

export const getInitialValues = (): ContactOrgSignupFormValues => {
  const localStorageData = localStorage.getItem('contactOrg');

  if (localStorageData) return JSON.parse(localStorageData);
  return {
    organizationPhoneNumber: '',
    organizationEmail: '',
    organizationWebSite: '',
    address: GetLocationIdByAddressRequest.fromJS({
      countryName: '',
      stateName: '',
      cityName: '',
      streetName: '',
      buildingNumber: '',
      zipCode: '',
      pobox: '',
      longitude: 0,
      latitude: 0,
    }),
  };
};

export const validationSchema = Yup.object().shape({
  organizationPhoneNumber: requiredPhoneNumberSchema,
  organizationEmail: emailSchema,
  organizationWebSite: websiteSchema,
  address: orgAddressMandatorySchema,
});
