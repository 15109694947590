import {Dispatch} from 'redux';
import axios from 'axios';
import moment from 'moment';
//API
import {API} from 'core/API';
//redux
import {
  CANTMAKEIT,
  CHANGE_STATUS_MANUAL_OPPORTUNITY,
  CREATE_OPPORTUNITY,
  DELETE_OPPORTUNITY,
  SET_END_UPLOAD,
  GET_COORDINATORS_BY_ID,
  GET_OPPORTUNITIES,
  GET_OPPORTUNITIES_COUNT,
  GET_OPPORTUNITIES_REMINDER_TAPE,
  GET_OPPORTUNITY_BY_ID,
  JOIN_TO_OPPORTUNITY_BY_VOLUNTEER,
  REFRESH_PENDING_OPPORTUNITIES,
  RESET_CURRENT_OPPORTUNITY,
  RESET_OPPORTUNITIES_RECORDS,
  SET_ERROR,
  UPDATE_CURRENT_OPPORTUNITY,
  UPDATE_MANUAL_OPPORTUNITY,
  UPDATE_OPPORTUNITY,
  UPDATE_PROGRESS,
  SET_ERROR_UPLOAD,
  RESET_IS_NEED_REFRESH,
  SET_IS_NEED_REFRESH,
  CREATE_STAGED_OPPORTUNITY,
} from 'redux/actions-types';
//types
import {Item} from 'components/Volunteer/UploadSeveralPhotos/ItemPreview';
import {ManualOpportunityInitialValuesType} from 'core/types';
import {
  CreateManualOpportunityRequest,
  CreateUploadLinkRequest,
  DeleteOpportunityRequest,
  ICreateManualOpportunityRequest,
  IMyChesedRequest,
  RECURRENCE_POSSIBLE_UPDATE,
  ToggleOptOutStatusRequest,
} from '@joc/api-gateway/lib/api-client';
import {
  ChangeStatusOpportunityRequest,
  CreateOpportunityRequest,
  CreateStagedOpportunityRequest,
  DetachOpportunityVolunteersRequest,
  ICreateOpportunityRequest,
  ICreateStagedOpportunityRequest,
  IDateRange,
  IGetLocationAddressByIdResponse,
  IOpportunityResponse,
  ISearchOpportunitiesByGuestRequest,
  ISearchOpportunitiesRequest,
  IUpdateOpportunityRequest,
  OPPORTUNITY_STATUSES,
  SEARCH_OPPORTUNITIES_POSSIBLE_SORT,
  SearchOpportunitiesByGuestRequest,
  SearchOpportunitiesRequest,
  SearchUsersByOrgRequest,
  SortDirection,
  StatusOpportunityVolunteersRequest,
  StatusOpportunityVolunteersRequestStatus,
  TimeRangeRequest,
  UpdateManualOpportunityRequest,
  UpdateOpportunityRequest,
  USER_ORG_ROLES,
} from '@joc/api-gateway';
import i18n from 'components/translate';
//functions
import {
  convertBase64ToBlob,
  convertWhatTimeToGreenwich,
  getFileType,
  removeEmptyProps,
  getIsEmptyPaginationSkip,
} from 'core/functions';
//constants
import {UpcomingOpposTabQueryPreset} from 'core/constants';
import {LoadingPayload} from 'redux/loading-service/types';

const uploadSignatureToBackEnd = async (signature: string): Promise<string> => {
  const signatureBlobType = await convertBase64ToBlob(signature);
  const response = await API.uploadFile({
    data: signatureBlobType.photo,
    fileName: signatureBlobType.fileName,
  });

  if (!response.fileName) {
    return '';
  }

  return response.fileName;
};

const uploadFilesToBackEnd = async (items: Item[], dispatch: Dispatch): Promise<string[]> => {
  const uploadLinkResponse = await Promise.all(
    items.map((item) =>
      API.createPreSignedUrl(
        CreateUploadLinkRequest.fromJS({
          fileName: item.file?.name,
          fileType: item.file?.type,
        })
      )
    )
  );

  const s3BucketResponse = await Promise.all(
    uploadLinkResponse.map((link, i) =>
      axios.put(link.upoloadLink, items[i].file, {
        headers: {
          'Access-Control-Allow-Origin': null,
          'Content-Type': items[i].file?.type,
        },
        onUploadProgress: (progressEvent: ProgressEvent) => {
          const loadingPayload: LoadingPayload = {
            percent: Math.round((progressEvent.loaded / items[i].file!?.size) * 100),
            itemId: link.upoloadLink,
            filename: items[i].file?.name,
          };

          dispatch({
            type: UPDATE_PROGRESS,
            payload: loadingPayload,
          });
        },
      })
    )
  )
    .then((files) => {
      dispatch({
        type: SET_END_UPLOAD,
      });
      return files;
    })
    .catch((error) => {
      dispatch({
        type: SET_ERROR_UPLOAD,
      });
      return error;
    });

  if (!s3BucketResponse.length) {
    return [];
  }

  return uploadLinkResponse.map((item) => item.newFileName);
};

const createManualPreparation = async (values: ICreateManualOpportunityRequest, dispatch: Dispatch) => {
  let photosRes: string[] = [];

  const formatedStartDate = moment(moment((values as ICreateOpportunityRequest).startDate).toISOString()).set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });

  const formatedEndDate = moment(formatedStartDate).add(
    (values as ManualOpportunityInitialValuesType).endTime,
    'hours'
  );

  if (values?.filePathes?.length) {
    photosRes = await uploadFilesToBackEnd(values.filePathes as unknown as Item[], dispatch);
  }

  return {
    ...(values as ICreateOpportunityRequest),
    startDate: formatedStartDate.toDate(),
    endDate: formatedEndDate.toDate(),
    filePathes: photosRes as any,
    imagePath: photosRes.reduceRight((res, url) => (getFileType(url) === 'mp4' ? res : url), ''),
    recipientEmail: values.recipientEmail ? values.recipientEmail : null,
    recipientPhoneNumber: values.recipientPhoneNumber ? values.recipientPhoneNumber : null,
  } as ICreateOpportunityRequest;
};

const createManualPreparationAdmin = async (values: any, dispatch: Dispatch) => {
  let photosRes: string[] = [];

  const formatedStartDate = moment(moment((values as ICreateOpportunityRequest).startDate).toISOString()).set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });

  const formatedEndDate = moment(formatedStartDate).add(
    (values as ManualOpportunityInitialValuesType).endTime,
    'hours'
  );

  if (values.filePathes.length) {
    photosRes = await uploadFilesToBackEnd(values.filePathes, dispatch);
  }

  return {
    ...(values as ICreateOpportunityRequest),
    startDate: formatedStartDate.toDate(),
    endDate: formatedEndDate.toDate(),
    filePathes: photosRes as any,
    imagePath: photosRes.reduceRight((res, url) => (getFileType(url) === 'mp4' ? res : url), ''),
    recipientEmail: values.recipientEmail ? values.recipientEmail : null,
    recipientPhoneNumber: values.recipientPhoneNumber ? values.recipientPhoneNumber : null,
  } as ICreateOpportunityRequest;
};

const updateManualPreparation = async (values: UpdateManualOpportunityRequest, dispatch: Dispatch) => {
  let photosRes: string[] = [];

  const formatedStartDate = moment(moment((values as UpdateManualOpportunityRequest).startDate).toISOString()).set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });

  const formattedEndDate = moment(formatedStartDate).add(
    (values as unknown as ManualOpportunityInitialValuesType).endTime,
    'hours'
  );

  if (values.filePathes?.length) {
    const files = values.filePathes as unknown as Item[];
    const uploadedFiles = files?.filter((file) => file.isNew);
    const backEndFiles = files?.filter((file) => !file.isNew).map((file) => file.src);
    if (uploadedFiles?.length) {
      const uploadedFilesUrls = await uploadFilesToBackEnd(uploadedFiles, dispatch);
      photosRes = [...backEndFiles, ...uploadedFilesUrls];
    } else {
      photosRes = backEndFiles;
    }
  }

  return {
    ...(values as UpdateManualOpportunityRequest),
    startDate: formatedStartDate.toDate(),
    endDate: formattedEndDate.toDate(),
    filePathes: photosRes as any,
    imagePath: photosRes.reduceRight((res, url) => (getFileType(url) === 'mp4' ? res : url), ''),
    recipientEmail: values.recipientEmail ? values.recipientEmail : null,
    recipientPhoneNumber: values.recipientPhoneNumber ? values.recipientPhoneNumber : null,
  } as UpdateManualOpportunityRequest;
};

const updateAdminManualPreparation = async (
  values: IUpdateOpportunityRequest,
  dispatch: Dispatch,
  isNeedUpdateEndTime?: boolean
) => {
  let photosRes: string[] = [];

  const formatedStartDate = moment(moment((values as IUpdateOpportunityRequest).startDate).toISOString()).set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });

  const formatedEndDate = moment(formatedStartDate).add(
    (values as unknown as ManualOpportunityInitialValuesType).endTime,
    'hours'
  );

  if (values.filePathes?.length) {
    const files = values.filePathes as unknown as Item[];
    const uploadedFiles = files?.filter((file) => file.isNew);
    const backEndFiles = files?.filter((file) => !file.isNew).map((file) => file.src);
    if (uploadedFiles?.length) {
      const uploadedFilesUrls = await uploadFilesToBackEnd(uploadedFiles, dispatch);
      photosRes = [...backEndFiles, ...uploadedFilesUrls];
    } else {
      photosRes = backEndFiles;
    }
  }

  return {
    ...(values as IUpdateOpportunityRequest),
    startDate: isNeedUpdateEndTime ? formatedStartDate.toDate() : values.startDate,
    endDate: isNeedUpdateEndTime ? formatedEndDate.toDate() : values.endDate,
    filePathes: photosRes as any,
    imagePath: photosRes.reduceRight((res, url) => (getFileType(url) === 'mp4' ? res : url), ''),
  } as unknown as UpdateManualOpportunityRequest;
};

export const getOpportunities =
  (searchBody: ISearchOpportunitiesRequest) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      if (!searchBody.fullTextSearch?.value) delete searchBody.fullTextSearch;

      const recordsResponse = await API.searchOpportunitiesByOrganisation(
        undefined,
        undefined,
        removeEmptyProps(searchBody)
      );

      const isEmptyPaginationSkip = getIsEmptyPaginationSkip(searchBody.pagination);
      if (isEmptyPaginationSkip) dispatch({type: RESET_OPPORTUNITIES_RECORDS});

      dispatch({
        type: GET_OPPORTUNITIES,
        payload: {records: recordsResponse.records, total: recordsResponse.total},
      });
    } catch (error) {
      dispatch({type: SET_ERROR, payload: {state: true, message: error?.response?.message || error.message}});
    }
  };

export const getOpportunityById =
  (id: number) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const response = await API.getOpportunityById(id, '');
      dispatch({
        type: GET_OPPORTUNITY_BY_ID,
        payload: response,
      });
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const resetOpportunities =
  () =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: RESET_OPPORTUNITIES_RECORDS,
    });
  };

export const resetCurrentOpportunity =
  () =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: RESET_CURRENT_OPPORTUNITY,
    });
  };

export const getOpportunitiesCount =
  (orgId: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const response = await API.countsOpportunities(orgId);
      dispatch({type: GET_OPPORTUNITIES_COUNT, payload: response});
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const createOpportunity =
  (values: ICreateOpportunityRequest) =>
  async (dispatch: Dispatch): Promise<IOpportunityResponse> => {
    try {
      if (values.organisationId) {
        const response = await API.createOpportunity(undefined, undefined, CreateOpportunityRequest.fromJS(values));
        dispatch({type: CREATE_OPPORTUNITY, payload: response});
        return response;
      }
      throw new Error(i18n.t('errors:cantFindOrgId'));
    } catch (error) {
      throw error;
    }
  };

export const createStagedOpportunity =
  (values: ICreateStagedOpportunityRequest, isStagedTab: boolean) =>
  async (dispatch: Dispatch): Promise<IOpportunityResponse> => {
    try {
      if (values.organisationId) {
        const response = await API.createStagedOpportunity(
          values.organisationId,
          CreateStagedOpportunityRequest.fromJS(values)
        );
        dispatch({type: CREATE_STAGED_OPPORTUNITY, payload: {opportunity: response, isStagedTab}});
        return response;
      }
      throw new Error(i18n.t('errors:cantFindOrgId'));
    } catch (error) {
      throw error;
    }
  };

export const createManualOpportunityAdmin =
  (values: ICreateOpportunityRequest) =>
  async (dispatch: Dispatch): Promise<IOpportunityResponse> => {
    try {
      if (values.organisationId) {
        const requestBody = await createManualPreparationAdmin(values, dispatch);
        const response = await API.createOpportunity(
          undefined,
          undefined,
          CreateOpportunityRequest.fromJS(requestBody)
        );
        dispatch({type: CREATE_OPPORTUNITY, payload: response});
        return response;
      }
      throw new Error(i18n.t('errors:cantFindOrgId'));
    } catch (error) {
      throw error;
    }
  };

export const createOpportunityBasedSmartGroup =
  (values: ICreateOpportunityRequest) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const responseOpportunity = await API.createOpportunity(
        undefined,
        undefined,
        CreateOpportunityRequest.fromJS(values)
      );
      dispatch({type: CREATE_OPPORTUNITY, payload: responseOpportunity});
    } catch (error) {
      throw new Error(error?.response?.message || error.message);
    }
  };

export const createManualOpportunity =
  (values: ICreateManualOpportunityRequest) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const requestBody = await createManualPreparation(values, dispatch);
      const response = await API.createManualOpportunity(CreateManualOpportunityRequest.fromJS(requestBody));
      dispatch({type: CREATE_OPPORTUNITY, payload: response});
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const updateOpportunity = (id: number, values: IUpdateOpportunityRequest) => async (dispatch: Dispatch) => {
  try {
    const response = await API.updateOpportunity(id, UpdateOpportunityRequest.fromJS(values));
    dispatch({type: UPDATE_OPPORTUNITY, payload: response});
    dispatch(setIsNeedRefresh());

    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const updateAdminOpportunity =
  (id: number, values: IUpdateOpportunityRequest, isNeedUpdateEndTime: boolean) => async (dispatch: Dispatch) => {
    try {
      const requestBody = await updateAdminManualPreparation(values, dispatch, isNeedUpdateEndTime);
      const response = await API.updateOpportunity(id, UpdateOpportunityRequest.fromJS(requestBody));
      dispatch({type: UPDATE_OPPORTUNITY, payload: response});
      return response;
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const updateManualOpportunity =
  (id: number, values: UpdateManualOpportunityRequest) => async (dispatch: Dispatch) => {
    const requestBody = await updateManualPreparation(values, dispatch);
    const response = await API.updateManualOpportunity(id, UpdateManualOpportunityRequest.fromJS(requestBody));
    dispatch({type: UPDATE_MANUAL_OPPORTUNITY, payload: response});
    dispatch(setIsNeedRefresh());
  };

export const deleteOpportunity =
  (id: number, recurrencePossibleDelete?: RECURRENCE_POSSIBLE_UPDATE) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      await API.deleteOpportunity(id, DeleteOpportunityRequest.fromJS({recurrencePossibleDelete}));
      dispatch({type: DELETE_OPPORTUNITY, payload: id});
      dispatch(setIsNeedRefresh());
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const deleteManualOpportunity =
  (id: number) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      await API.deleteManualOpportunity(id);
      dispatch({type: DELETE_OPPORTUNITY, payload: id});
      dispatch(setIsNeedRefresh());
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const joinToOpportunity = (id: number) => async (dispatch: Dispatch) => {
  try {
    await API.attachByVolunteer(id);
    const updatedOpportunity = await API.getOpportunityById(id, '');
    dispatch({type: UPDATE_OPPORTUNITY, payload: updatedOpportunity});
  } catch (error) {
    throw new Error(error.message);
  }
};

export const joinToOpportunityCurrent = (id: number) => async (dispatch: Dispatch) => {
  try {
    await API.attachByVolunteer(id);
    const updatedOpportunity = await API.getOpportunityById(id, '');
    dispatch({type: JOIN_TO_OPPORTUNITY_BY_VOLUNTEER, payload: updatedOpportunity});
  } catch (error) {
    throw new Error(error.message);
  }
};

export const changeStatusVolunteers =
  (id: number, volunteerIds: Array<number>, status: StatusOpportunityVolunteersRequestStatus) =>
  async (dispatch: Dispatch) => {
    try {
      await API.changeStatusVolunteers(
        id,
        volunteerIds.map((volunteerId) =>
          StatusOpportunityVolunteersRequest.fromJS({
            volunteerId,
            status,
          })
        )
      );
      const updatedOpportunity = await API.getOpportunityByAdmin(id, localStorage.getItem('organisationId') || '');

      dispatch({type: UPDATE_OPPORTUNITY, payload: updatedOpportunity});
    } catch (error) {
      dispatch({type: SET_ERROR, payload: {state: true, message: error?.response?.message || error.message}});
    }
  };

export const detachVolunteers = (id: number, volunteerIds: Array<number>) => async (dispatch: Dispatch) => {
  try {
    await Promise.all(
      volunteerIds.map(async (volunteerId) =>
        API.detachVolunteer(
          id,
          DetachOpportunityVolunteersRequest.fromJS({
            volunteerId,
          })
        )
      )
    );
    const updatedOpportunity = await API.getOpportunityByAdmin(id, localStorage.getItem('organisationId') || '');
    dispatch({type: UPDATE_OPPORTUNITY, payload: updatedOpportunity});
  } catch (error) {
    dispatch({type: SET_ERROR, payload: {state: true, message: error?.response?.message || error.message}});
  }
};

export const cantMakeIt =
  (id: number, userVolunteerId: string, geolocation: IGetLocationAddressByIdResponse | undefined) =>
  async (dispatch: Dispatch) => {
    try {
      if (id && userVolunteerId) {
        await API.toggleOpportunityVolunteerStatusCanMakeIt(id, ToggleOptOutStatusRequest.fromJS(undefined));
        dispatch({type: CANTMAKEIT, payload: {id, userVolunteerId}});
        const updatedOpportunity = await API.searchOpportunitiesByVolunteer(
          undefined,
          geolocation ? `${geolocation.latitude},${geolocation.longitude}` : undefined,
          SearchOpportunitiesRequest.fromJS({
            opportunityIds: [id],
          })
        );
        const payloadData = {data: updatedOpportunity, opportunityId: id};
        dispatch({type: UPDATE_CURRENT_OPPORTUNITY, payload: payloadData});
      }
    } catch (error) {
      console.error(error);
      throw new Error(error.message);
    }
  };

export const getOpportunitiesReminderTape = (orgId: number, dateRange: IDateRange) => async (dispatch: Dispatch) => {
  try {
    const opportunitiesResponse = await API.searchOpportunitiesByOrganisation(
      undefined,
      undefined,
      SearchOpportunitiesRequest.fromJS({
        ...UpcomingOpposTabQueryPreset,
        startDay: dateRange,
        organisationId: orgId,
      })
    );
    dispatch({type: GET_OPPORTUNITIES_REMINDER_TAPE, payload: opportunitiesResponse});
  } catch (error) {
    throw new Error(error.message);
  }
};

export const changeStatusManualOpportunity =
  (opportunityId: number, opportunityStatus: OPPORTUNITY_STATUSES) => async (dispatch: Dispatch) => {
    try {
      await API.changeStatusOpportunity(
        undefined,
        ChangeStatusOpportunityRequest.fromJS({opportunityId: +opportunityId, opportunityStatus})
      );

      dispatch({
        type: CHANGE_STATUS_MANUAL_OPPORTUNITY,
        payload: {id: opportunityId, opportunityStatus},
      });
    } catch (error) {
      dispatch({type: SET_ERROR, payload: {state: true, message: error?.response?.message || error.message}});
    }
  };

export const getOpportunitiesByVolunteer =
  (address: IGetLocationAddressByIdResponse | undefined, searchBody: any) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const requestBody = {
        ...searchBody,
        sort: {
          sortBy: SEARCH_OPPORTUNITIES_POSSIBLE_SORT.StartDayStartTime,
          sortDir: SortDirection.DESC,
        },
      };

      const recordsResponse = await API.searchOpportunitiesByVolunteer(
        undefined,
        address ? `${address.latitude},${address.longitude}` : undefined,
        removeEmptyProps(requestBody)
      );
      if (searchBody.pagination.skip === 0) dispatch({type: RESET_OPPORTUNITIES_RECORDS});
      dispatch({
        type: GET_OPPORTUNITIES,
        payload: {records: recordsResponse.records, total: recordsResponse.total},
      });
    } catch (error) {
      dispatch({type: SET_ERROR, payload: {state: true, message: error?.response?.message || error.message}});
    }
  };

export const findChesed =
  (address: IGetLocationAddressByIdResponse | undefined, searchBody: any) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const requestBody = {
        ...searchBody,
        sort: {
          sortBy: SEARCH_OPPORTUNITIES_POSSIBLE_SORT.StartDayStartTime,
          sortDir: SortDirection.DESC,
        },
      };

      const recordsResponse = await API.findChesed(
        address ? `${address.latitude},${address.longitude}` : undefined,
        removeEmptyProps(requestBody)
      );
      if (searchBody.pagination.skip === 0) dispatch({type: RESET_OPPORTUNITIES_RECORDS});
      dispatch({
        type: GET_OPPORTUNITIES,
        payload: {records: recordsResponse.records, total: recordsResponse.total},
      });
    } catch (error) {
      dispatch({type: SET_ERROR, payload: {state: true, message: error?.response?.message || error.message}});
    }
  };

export const getMyChesed =
  (address: IGetLocationAddressByIdResponse | undefined, searchBody: IMyChesedRequest) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const requestBody = {
        ...searchBody,
        sort: {
          sortBy: SEARCH_OPPORTUNITIES_POSSIBLE_SORT.StartDayStartTime,
          sortDir: SortDirection.DESC,
        },
      };

      const recordsResponse = await API.myChesed(
        address ? `${address.latitude},${address.longitude}` : undefined,
        removeEmptyProps(requestBody)
      );
      if (searchBody?.pagination?.skip === 0) dispatch({type: RESET_OPPORTUNITIES_RECORDS});
      dispatch({
        type: GET_OPPORTUNITIES,
        payload: {records: recordsResponse.records, total: recordsResponse.total},
      });
    } catch (error) {
      dispatch({type: SET_ERROR, payload: {state: true, message: error?.response?.message || error.message}});
    }
  };

export const getCoordinators = (organizationId: number) => async (dispatch: Dispatch) => {
  try {
    const orgReqBody = {roleNames: [USER_ORG_ROLES.COORDINATOR, USER_ORG_ROLES.ADMIN]};
    const response = await API.organisationSearch(String(organizationId), SearchUsersByOrgRequest.fromJS(orgReqBody));
    dispatch({
      type: GET_COORDINATORS_BY_ID,
      payload: response.records,
    });
  } catch (error) {
    dispatch({type: SET_ERROR, payload: {state: true, message: error?.response?.message || error.message}});
  }
};
export const getOpportunitiesByGuest =
  (searchBody: ISearchOpportunitiesByGuestRequest) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const requestBody = {
        ...searchBody,
        sort: {
          sortBy: SEARCH_OPPORTUNITIES_POSSIBLE_SORT.StartDayStartTime,
          sortDir: SortDirection.ASC,
        },
      };
      const timeRange = convertWhatTimeToGreenwich(searchBody.whatTime);
      if (timeRange?.startTime)
        requestBody.whatTime = TimeRangeRequest.fromJS({
          ...requestBody.whatTime,
          startTime: timeRange.startTime,
        });
      if (timeRange?.endTime)
        requestBody.whatTime = TimeRangeRequest.fromJS({
          ...requestBody.whatTime,
          endTime: timeRange.endTime,
        });
      const recordsResponse = await API.searchOpportunitiesByGuest(
        undefined,
        SearchOpportunitiesByGuestRequest.fromJS(requestBody)
      );
      dispatch({
        type: GET_OPPORTUNITIES,
        payload: {records: recordsResponse.records, total: recordsResponse.total},
      });
    } catch (error) {
      dispatch({type: SET_ERROR, payload: {state: true, message: error?.response?.message || error.message}});
    }
  };

export const refreshPendingVolunteers = () => ({type: REFRESH_PENDING_OPPORTUNITIES});
export const setIsNeedRefresh = () => ({type: SET_IS_NEED_REFRESH});
export const resetIsNeedRefresh = () => ({type: RESET_IS_NEED_REFRESH});
