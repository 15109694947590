import React, {useMemo} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import WarningIcon from 'assets/icons/dialog/warning.png';

interface NewDialogProps {
  open: boolean;
  onClose: () => void;
  type?: 'warning' | 'error';
  title?: string;
  content: string | React.ReactNode;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  onPrimaryClick?: () => void;
  onSecondaryClick?: () => void;
}

const NewDialog: React.FC<NewDialogProps> = ({
  open,
  onClose,
  type = 'warning',
  title = 'Important',
  content,
  primaryButtonText = 'I understand',
  secondaryButtonText = 'Go back',
  onPrimaryClick,
  onSecondaryClick,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const icon = useMemo(() => (type === 'warning' ? WarningIcon : ''), [type]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: isMobile
          ? {
              justifyContent: 'center',
              gap: '16px',
              width: '328px',
              height: 'auto',
              padding: '24px 12px 24px 12px',
              borderRadius: '12px',
            }
          : {
              justifyContent: 'center',
              gap: '16px',
              width: '328px',
              height: 'auto',
              padding: '24px 12px 24px 12px',
              borderRadius: '16px',
            },
      }}
    >
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', gap: '12px'}}>
        <img width={84} height={84} src={icon} alt={type} />
        <Typography
          sx={{fontFamily: '"Plus Jakarta Sans", sans-serif', fontSize: 18, fontWeight: 600, lineHeight: '22.68px'}}
          variant="h6"
          fontWeight="bold"
        >
          {title}
        </Typography>
      </div>
      <DialogContent sx={{padding: 'unset', textAlign: 'center', paddingX: '20px'}}>
        {typeof content === 'string' ? <Typography>{content}</Typography> : content}
      </DialogContent>
      <DialogActions
        sx={{padding: 'unset', marginTop: '16px', justifyContent: 'center', flexDirection: 'column', gap: '8px'}}
      >
        {primaryButtonText && (
          <Button
            sx={{
              height: '36px',
              borderRadius: '100px',
              backgroundColor: '#E2E6FF !important',
              color: '#214ba5',
              fontFamily: '"Plus Jakarta Sans", sans-serif',
              textTransform: 'none',
              fontSize: '16px',
              lineHeight: '20px',
              fontWeight: 600,
            }}
            type="button"
            variant="contained"
            fullWidth
            onClick={onPrimaryClick || onClose}
          >
            {primaryButtonText}
          </Button>
        )}
        {secondaryButtonText && (
          <Button
            sx={{
              height: '20px',
              backgroundColor: 'transparent !important',
              border: 'unset !important',
              color: '#214ba5',
              fontFamily: '"Plus Jakarta Sans", sans-serif',
              textTransform: 'none',
              textDecoration: 'underline',
              fontSize: '14px',
              lineHeight: '20px',
              fontWeight: 600,
            }}
            type="button"
            variant="text"
            color="primary"
            onClick={onSecondaryClick || onClose}
          >
            {secondaryButtonText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default NewDialog;
