import {FC} from 'react';
import {Link} from 'react-router-dom';
import {urls} from 'core/appUrls';
import {useTranslation} from 'react-i18next';
//styles
import styles from './index.module.scss';

export const ManyRequestsMessage: FC = () => {
  const {t} = useTranslation(['errors', 'common']);

  return (
    <div className={styles.wrapper}>
      <span>{t('errors:errorToManyRequest.title')}</span>
      <span>
        {t('errors:errorToManyRequest.subTitle')}
        <Link className={styles.subtitle} to={urls.authRecoveryPassword}>
          {t('common:resetYourPassword')}
        </Link>
      </span>
    </div>
  );
};
