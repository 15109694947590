import {Dispatch, FC, RefObject, SetStateAction, useState} from 'react';
import axios, {AxiosResponse} from 'axios';
import {useReactToPrint} from 'react-to-print';
import {saveAs} from 'file-saver';
import {useTranslation} from 'react-i18next';
import {flip, shift, useFloating} from '@floating-ui/react-dom';
import {useClickAway} from 'react-use';

//redux
import {Store} from 'redux/root';
import {setError} from 'redux/error-service/action';
import {connect, ConnectedProps} from 'react-redux';
import {selectorGetOrgId} from 'redux/organization-service/selector';
import {selectorGetOpportunitiesRecords} from 'redux/opportunities-service/selector';

//context
import {useOpportunitiesContext} from 'pages/Organization/Opportunities/context';

//functions
import {removeEmptyProps} from 'core/functions';

//types
import {ISearchOpportunitiesRequest} from '@joc/api-gateway';

//components
import ButtonImage from 'shared/components/Buttons/ButtonImage';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import PopupCreate from '../../OpportunityPopups/PopupCreate';
import PopupMain from '../../OpportunityPopups/PopupMain';

//styles
import styles from './Actions.module.scss';
import PopupStaged from '../../OpportunityPopups/PopupStaged';

type ActionsParentProps = {
  printRef: RefObject<HTMLTableElement>;
  setIsPrintableTable: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
  requestBody: ISearchOpportunitiesRequest | null;
  disabled?: boolean;
  isHideDownloadXlsx?: boolean;
  isHideCalendar?: boolean;
  isHidePrint?: boolean;
  isStagedTab?: boolean;
};

const Actions: FC<ActionsProps> = ({
  printRef,
  setIsPrintableTable,
  opportunitiesRecords,
  isLoading,
  orgId,
  requestBody,
  setError,
  disabled,
  isHideDownloadXlsx = false,
  isHideCalendar = false,
  isHidePrint = false,
  isStagedTab = false,
}: ActionsProps) => {
  const [isOpenCreateActions, setIsOpenCreateActions] = useState(false);

  const [isShowCreatePopup, setIsShowCreatePopup] = useState(false);
  const [isShowCreateStagedPopup, setIsShowCreateStagedPopup] = useState(false);

  const {setSidebarVisibility} = useOpportunitiesContext();

  const {t} = useTranslation(['errors', 'tooltips', 'buttons']);

  const toggleAction = () => setIsOpenCreateActions((prev) => !prev);

  const buttonDownloadClickHandler = async () => {
    try {
      if (!orgId) throw new Error(`${t('errors:sorry')} ${t('errors:cantFindOrgId')}`);

      delete requestBody?.pagination;
      delete requestBody?.sort;
      if (!requestBody?.fullTextSearch?.value) delete requestBody?.fullTextSearch;
      requestBody = removeEmptyProps(requestBody);
      // TODO: create helper function (first arg: requestBody, second one: object, which we will add later)

      const accessToken = localStorage.getItem('accessToken');

      if (!accessToken) throw new Error(`${t('errors:sorry')} ${t('errors:cantFindAccessToken')}`);

      const opportunitiesResponse: AxiosResponse<Blob> = await axios(
        `${process.env.REACT_APP_JOC_API}/opportunities/export-opportunities-by-organisation`,
        {
          method: 'POST',
          responseType: 'blob',
          headers: {
            'x-organization-id': orgId,
            Authorization: `Bearer ${accessToken}`,
          },
          data: {...requestBody, organisationId: orgId},
        }
      );

      saveAs(opportunitiesResponse.data, `${!!requestBody?.status ? requestBody?.status : 'ALL'}_opportunities_list`);
    } catch (error) {
      setError(error?.response?.message || error.message, error.code);
    }
  };

  const calendarClickHandler = () =>
    setSidebarVisibility(({isShowCalendar}) => ({isShowFilter: false, isShowCalendar: !isShowCalendar}));

  const handlePrint = useReactToPrint({
    onBeforeGetContent: () => setIsPrintableTable(true),
    onAfterPrint: () => setIsPrintableTable(false),
    content: () => printRef.current,
  });

  const {refs, floatingStyles} = useFloating({
    placement: 'bottom-end',
    middleware: [flip(), shift()],
  });

  useClickAway(refs.floating, () => setIsOpenCreateActions(false));

  return (
    <div className={styles.wrapper}>
      {isHideCalendar && isHideDownloadXlsx && isHidePrint ? <div>&nbsp;</div> : null}
      {!isHidePrint && (
        <ButtonImage
          clickHandler={handlePrint}
          disabled={!opportunitiesRecords.length && !isLoading}
          viewStyle="print"
          title={t('tooltips:printList')}
        />
      )}
      {!isHideDownloadXlsx && (
        <ButtonImage
          clickHandler={buttonDownloadClickHandler}
          disabled={!opportunitiesRecords.length && !isLoading}
          viewStyle="download"
          title={t('tooltips:exportList')}
        />
      )}
      {!isHideCalendar && (
        <ButtonImage
          clickHandler={calendarClickHandler}
          disabled={!opportunitiesRecords.length && !isLoading}
          viewStyle="calendar"
          title={t('tooltips:calendar')}
        />
      )}
      <div ref={refs.setReference}>
        <ButtonDefault
          title={t('buttons:button.create')}
          classList={['primary', 'sm']}
          clickHandler={toggleAction}
          disabled={disabled}
        />
      </div>

      {isOpenCreateActions && (
        <div ref={refs.setFloating} style={floatingStyles} className={styles.dropdownMenu} onClick={toggleAction}>
          <div className={styles.dropdownMenu__item} onClick={() => setIsShowCreatePopup(true)}>
            <span className={styles.dropdownMenu__item_title}>
              <b>Default</b> Opportunity
            </span>
          </div>
          <div className={styles.dropdownMenu__item} onClick={() => setIsShowCreateStagedPopup(true)}>
            <span className={styles.dropdownMenu__item_title}>
              <b>Staged</b> Opportunity
            </span>
          </div>
        </div>
      )}

      {isShowCreatePopup && (
        <PopupMain setIsShowPopup={setIsShowCreatePopup}>
          <PopupCreate setIsShowPopup={setIsShowCreatePopup} />
        </PopupMain>
      )}

      {isShowCreateStagedPopup && (
        <PopupStaged
          organisationId={String(orgId)}
          setIsShowPopup={setIsShowCreateStagedPopup}
          isStagedTab={isStagedTab}
        />
      )}
    </div>
  );
};

const mapStateToProps = (store: Store) => ({
  opportunitiesRecords: selectorGetOpportunitiesRecords(store),
  orgId: selectorGetOrgId(store),
});

const mapDispatchToProps = {
  setError,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ActionsProps = ConnectedProps<typeof connector> & ActionsParentProps;

export default connector(Actions);
