import {Dispatch, FC, SetStateAction} from 'react';
import cx from 'classnames';
//types
import {IInviteUserRequest} from '@joc/api-gateway';
//styles
import styles from './ButtonSwitch.module.scss';

type ButtonSwitchProps = {
  changeSwitch: Dispatch<SetStateAction<boolean>>;
  switchState: boolean;
  titleOptionFirst: string;
  titleOptionSecond: string;
  showConfirmationChange?: any;
  parentClassName?: string;
  disabled?: boolean;
  invitedList?: IInviteUserRequest[];
  isEmpty?: any;
  isHideSecondOption?: boolean;
};

const ButtonSwitch: FC<ButtonSwitchProps> = ({
  titleOptionFirst,
  titleOptionSecond,
  switchState,
  showConfirmationChange,
  changeSwitch,
  parentClassName,
  disabled,
  invitedList,
  isEmpty,
  isHideSecondOption = false,
}: ButtonSwitchProps) => {
  return (
    <div className={cx(styles.switch, parentClassName && parentClassName)}>
      <div
        className={cx(styles.switch__item, switchState && styles.switch__item_active, {
          [styles.disabled]: disabled,
        })}
        onClick={() => {
          invitedList?.length ? showConfirmationChange(true) : changeSwitch(true);
        }}
      >
        {titleOptionFirst}
      </div>
      {!isHideSecondOption && (
        <div
          className={cx(styles.switch__item, !switchState && styles.switch__item_active, {
            [styles.disabled]: disabled,
          })}
          onClick={() => {
            isEmpty ? showConfirmationChange(true) : changeSwitch(false);
          }}
        >
          {titleOptionSecond}
        </div>
      )}
    </div>
  );
};

export default ButtonSwitch;
