import {makeStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import {Dispatch, FC, SetStateAction} from 'react';
import {ReasonType} from 'pages/Organization/Opportunities/interfaces';
import VolunteersFilters from 'components/Organization/Volunteers/VolunteersFilters';

const useStyles = makeStyles({
  paper: {
    zIndex: 100,
  },
});

interface IVolunteerSidebarProps {
  isOpen: boolean;
  isCloseFilters: boolean;
  changeVisibilityFilters: () => void;
  setIsClearSearch?: Dispatch<SetStateAction<boolean>>;
  handleClose: (event: {}, reason: ReasonType) => void | undefined;
}

export const VolunteerSidebar: FC<IVolunteerSidebarProps> = ({
  isOpen,
  handleClose,
  isCloseFilters,
  setIsClearSearch,
  changeVisibilityFilters,
}) => {
  const classes = useStyles();
  return (
    <Drawer
      anchor="right"
      variant="persistent"
      classes={{
        paper: classes.paper,
      }}
      open={isOpen}
      onClose={handleClose}
    >
      <VolunteersFilters
        isCloseFilters={isCloseFilters}
        setIsClearSearch={setIsClearSearch}
        changeVisibilityFilters={changeVisibilityFilters}
      />
    </Drawer>
  );
};
